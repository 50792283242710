import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Link, useHistory, useLocation } from 'react-router-dom';


const NavLink = (props) => {
    // const [isActive, setActive] = useState("");
    var isActive = false

    const location = useLocation();


    // console.log(this.context.router.route.location.pathname);
    // console.log(location.pathname);
    if (location.pathname == props.to) {
        isActive = true;


    }
    else {
        isActive = false;
    }

    // var className = ;

    return (
        <Link class={isActive ? 'active' : ''} {...props}>
            {props.children}
        </Link>
    );
}


// NavLink.contextTypes = {
//     router: PropTypes.object
// };

export default NavLink;