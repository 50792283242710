import { useEffect, useState } from "react";
import apiRequest from "../services/ServiceRequest";
import Header from "./header";
import Sidebar from "./menu";
import * as Url from '../../src/services/ServiceConstant';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const CreateAddon = (props) => {
    toast.configure()
    const [addons, setAddons] = useState([]);
    const handleCallback = async (productId) => {
        var productId = props.location.state;
        var addons = await apiRequest([], Url.BASE_URL + `/Products/${productId}?filter={"include":[{"relation":"addOns","scope":{"where":{"id_deleted":`+false+`},"include":"category"}}]}`, 'get')
        const addonsSegregate = addons.data.addOns.reduce((r, { addoncategoryId, ...objs }) => {
            if(!r[addoncategoryId]) r[addoncategoryId] = { addoncategoryId, data: [objs]}
            else r[addoncategoryId].data.push(objs);
            return r;
          }, {})
        var addonsLoop = Object.values(addonsSegregate);
        setAddons(addonsLoop);
    }
    useEffect(async () => {
        handleCallback(props.location.state);
        await apiRequest([],Url.BASE_URL+'/delete-empty-addons','get');
    },[]);
    const notify = (message) => {
        toast.success(message)
    }
    const errornotify = (message) => {
        toast.error(message)
    }
    const ChangeHandler = async(categoryindex, addonindex, addonvalue, addonproperty) =>
    {
        if(addonproperty=='name')
        {
            addons[categoryindex].data[addonindex].name = addonvalue;
        }
        if(addonproperty=='calories')
        {
            addons[categoryindex].data[addonindex].calories = addonvalue;
        }
        if(addonproperty=='price')
        {
            addons[categoryindex].data[addonindex].price = addonvalue;
        }
        if(addonproperty=='required')
        {
            addons[categoryindex].data[addonindex].required = addonvalue;
        }
        if(addonproperty=='default')
        {
            addons[categoryindex].data[addonindex].default = addonvalue;
        }
        setAddons([...addons]);
    }
    const PatchAddon = async (categoryindex, addonindex, addonvalue, addonproperty, addonid, categoryid) => {
        if(document.getElementById(categoryindex).value=='' || document.getElementById(categoryindex).value==' ')
        {
            errornotify("Please enter the category name");
            return false;
        }
        if(addonproperty=='required' || addonproperty=='price')
        {
            if(addons[categoryindex].data[addonindex].price>0 && (addons[categoryindex].data[addonindex].required=='false' || addons[categoryindex].data[addonindex].required==false))
            {
                addons[categoryindex].data[addonindex].price = 0;
                errornotify("price should be 0 when removal allowed is no")
                setTimeout(function(){
                    handleCallback(0);
                },2000);
                return false;
            }else{
                if(addonid!="")
                {
                    if(addonproperty=='name')
                    {
                        addons[categoryindex].data[addonindex].name = addonvalue;
                    }
                    if(addonproperty=='calories')
                    {
                        addons[categoryindex].data[addonindex].calories = addonvalue;
                    }
                    if(addonproperty=='price')
                    {
                        addons[categoryindex].data[addonindex].price = addonvalue;
                    }
                    if(addonproperty=='required')
                    {
                        addons[categoryindex].data[addonindex].required = addonvalue;
                    }
                    if(addonproperty=='default')
                    {
                        addons[categoryindex].data[addonindex].default = addonvalue;
                    }
                    addons[categoryindex].data[addonindex].id_deleted = false;
                    var apiBody = addons[categoryindex].data[addonindex];
                    await apiRequest(apiBody, Url.BASE_URL+'/AddOns','patch');
                    notify("Updated successfully");
                }
                else
                {
                    var newaddon = {
                        name: " ",
                        price: 0,
                        note : " ",
                        description: " ",
                        required: true,
                        default: true,
                        quantity: " ",
                        selection : ["non"],
                        addoncategoryId : categoryid,
                        id_deleted : false
                    };
                    await apiRequest(newaddon, Url.BASE_URL+'/AddOns','post');
                    notify("Updated successfully");
                }
            }
        }else{
            if(addonid!="")
                {
                    if(addonproperty=='name')
                    {
                        addons[categoryindex].data[addonindex].name = addonvalue;
                    }
                    if(addonproperty=='calories')
                    {
                        addons[categoryindex].data[addonindex].calories = addonvalue;
                    }
                    if(addonproperty=='price')
                    {
                        addons[categoryindex].data[addonindex].price = addonvalue;
                    }
                    if(addonproperty=='required')
                    {
                        addons[categoryindex].data[addonindex].required = addonvalue;
                    }
                    if(addonproperty=='default')
                    {
                        addons[categoryindex].data[addonindex].default = addonvalue;
                    }
                    addons[categoryindex].data[addonindex].id_deleted = false;
                    var apiBody = addons[categoryindex].data[addonindex];
                    await apiRequest(apiBody, Url.BASE_URL+'/AddOns','patch');
                    notify("Updated successfully");
                }
        }
    };
    const ChangeCategoryHandler = (index, value, property) => {
        if(property=="name")
        {
            addons[index].data[0].category.name = value;
        }
        if(property=="max_addons_allowed")
        {
            addons[index].data[0].category.max_addons_allowed = value
        }
        if(property=="is_mandatory")
        {
            addons[index].data[0].category.is_mandatory = value
        }
        setAddons([...addons]);
    };
    const patchCategory = async(index, value, property, categoryid) => {
        if(categoryid!="")
        {
            if(property=="name")
            {
                addons[index].data[0].category.name = value;
                var name = value;
            } 
            if(property=="max_addons_allowed")
            {
                addons[index].data[0].category.max_addons_allowed = value;
                var max_addons_allowed = value;
            } 
            if(property=="is_mandatory")
            {
                addons[index].data[0].category.is_mandatory = value;
                var is_mandatory = value;
            } 
            var apiBody = {
                id : addons[index].addoncategoryId,
                name : name,
                max_addons_allowed : max_addons_allowed,
                is_mandatory : is_mandatory,
                product : props.location.state,
                id_deleted : false
            };
            await apiRequest(apiBody, Url.BASE_URL+'/AddOnCategories','patch');
            notify("updated successfuly");
        }
        else
        {
            var apiBody = {
                name : value,
                max_addons_allowed : 0,
                product : props.location.state,
                id_deleted : false
            };
            var addresponse = await apiRequest(apiBody, Url.BASE_URL+'/AddOnCategories','post');
            var newobj = {
                addoncategoryId : addresponse.data.id,
                name : " ",
                price : " ",
                note : " ",
                description : " ",
                required : false,
                default : false,
                selection : ["non"],
                quantity : " ",
                enable : false,
                id_deleted : false
            };
            var addnewaddon = await apiRequest(newobj, Url.BASE_URL+'/AddOns','post');
            var productAddOn = {
                productId : props.location.state,
                addonId : addnewaddon.data.id
            };
            await apiRequest(productAddOn, Url.BASE_URL+'/ProductAddOns','post');
            notify("Updated successfully");
        }
    };
    const appendCategoryWithAddOn = async() => {
        var newobj = {
            addoncategoryId : "",
            data : [{
                id : "",
                name : "",
                price : "",
                note : "",
                description : "",
                required : false,
                default : false,
                selection : ["non"],
                quantity : "",
                enable : false,
                category : {
                    id : "",
                    name : "",
                    enable : false,
                    maximum : "",
                    minimum : ""
                }
            }]
        };
        let preAddons = [...addons];
        preAddons.push(newobj);
        setAddons(preAddons);
        var apiBody = {
            name : " ",
            max_addons_allowed : 0,
            product : props.location.state,
            id_deleted : false
        };
        var addresponse = await apiRequest(apiBody, Url.BASE_URL+'/AddOnCategories','post');
        var newobj = {
            addoncategoryId : addresponse.data.id,
            name : " ",
            price : " ",
            note : " ",
            description : " ",
            required : false,
            default : false,
            selection : ["non"],
            quantity : " ",
            enable : false,
            id_deleted : false
        };
        var addnewaddon = await apiRequest(newobj, Url.BASE_URL+'/AddOns','post');
        var productAddOn = {
            productId : props.location.state,
            addonId : addnewaddon.data.id
        };
        await apiRequest(productAddOn, Url.BASE_URL+'/ProductAddOns','post');
        handleCallback(0);
    };
    const appendItem = async(categoryindex, categoryid) => {
        var newobj = {
            name : " ",
            price : " ",
            note : " ",
            addoncategoryId : categoryid,
            description : " ",
            required : false,
            default : false,
            selection : ["non"],
            quantity : " ",
            enable : false,
            id_deleted : false
        };
        let preAddons = [...addons];
        preAddons[categoryindex].data.push(newobj);
        setAddons(preAddons);
        var addnewaddon = await apiRequest(newobj, Url.BASE_URL+'/AddOns','post');
        var productAddOn = {
            productId : props.location.state,
            addonId : addnewaddon.data.id
        };
        await apiRequest(productAddOn, Url.BASE_URL+'/ProductAddOns','post');
        handleCallback(0);
    };
    const _onChangeImage = async (categoryindex, addonindex, images, addonproperty, id) => {
        if (images == null || images == undefined || images == [] || images == '' || images == {}) {
            alert("Image is required!!!");
        }
        else {
            let formData = new FormData();
            formData.append('images', images);
            let image_response = await apiRequest(formData, Url.UPLOAD_IMAGE, 'post')
            if (image_response.error) {
                errornotify("'Failed', response.error.message");
                return;
            }
            if(addonproperty=='image')
            {
                addons[categoryindex].data[addonindex].images = image_response.data.images;
            }
            var apiBody = addons[categoryindex].data[addonindex];
            await apiRequest(apiBody, Url.BASE_URL+'/AddOns','patch');
            notify("Updated successfully")
            setAddons([...addons]);
        }
    }
    return (
        <div>
            <html lang="en">
                <div class="sticky">
                    <Header parentCallback={handleCallback} />
                </div>
                <body>
                    <div class="middle_block">
                        <Sidebar/>
                        <div class="main_content">
                            <div class="container-fluid">
                                <div class="product_page">
                                    <a style={{ "text-decoration": "none", "color": "#000" }} href={'/product?id=' + props.location.state}>
                                    <img src="./back-arrow.png" /> Go Back
                                        <br /><br />
                                    </a>
                                    <h2 class="head_sty1">Create Add On</h2>
                                    <form>
                                    {
                    addons.length > 0
                        ? addons?.map((item1, index1) => {
                            var maxAddOns = item1.data.length;
                            if(item1?.data[0]?.category?.id_deleted==false){
                            return (
                                                        <section class="add_pro_category">
                                                            <div class="row">
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <label>Category  Name</label>
                                                                        <div>
                                                                            <input id={index1} type="text" onBlur={(e) => patchCategory(index1, e.target.value, 'name',item1.addoncategoryId)} onChange={(e) => ChangeCategoryHandler(index1, e.target.value, 'name')} value={item1?.data[0]?.category?.name} class="form-control"  />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <label>Max number of addons allowed</label>
                                                                        <div>
                                                                            <input type="number" onfocus={false} step="1" min="1" max={maxAddOns} onBlur={(e) => patchCategory(index1, e.target.value, 'max_addons_allowed',item1.addoncategoryId)} onChange={(e) => {
                                                                                if(e.target.value>maxAddOns){
                                                                                    alert("Input value exceeds number of addons!!");
                                                                                    return false;
                                                                                }
                                                                                ChangeCategoryHandler(index1, e.target.value, 'max_addons_allowed')
                                                                            }} value={item1?.data[0]?.category?.max_addons_allowed} class="form-control"  />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <label>Is Mandatory?</label>
                                                                        <div style={{width : 50}}>
                                                                            <input type="checkbox" checked={(item1?.data[0]?.category?.is_mandatory==true) ? true : false} onBlur={(e) => patchCategory(index1, e.target.checked, 'is_mandatory',item1.addoncategoryId)} onChange={(e) => ChangeCategoryHandler(index1, e.target.checked, 'is_mandatory')} value={item1?.data[0]?.category?.is_mandatory} class="form-control"  />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                item1.data.length > 0
                                                                    ? item1.data?.map((item2, index2) => {
                                                                        return(
                                                                            <section class="staff_profile_sec addon_pro_sec">
                                                                                <div class="staff_profile_pic_box">
                                                                                    <div class="staff_profile_img">
                                                                                        <img src={(item2.images == "" || item2.images == undefined) ? './img-placeholder.png' : Url.image_url+item2.images}></img>
                                                                                    </div>
                                                                                    <label class="custom-file-upload-category custFileUpload">
                                                                                        <input onChange={(e) => _onChangeImage(index1, index2, e.target.files[0], 'image', item2.id)}  type="file"/>
                                                                                        Upload Product Image
                                                                                    </label>
                                                                                    {   
                                                                                    }
                                                                                </div>
                                                                                <div class="staff_profile_form">
                                                                                    <div class="row">
                                                                                        <div class="col-md-6">
                                                                                            <div class="form-group">
                                                                                                <label>Add On Name</label>
                                                                                                <input type="text" onBlur={(e) => PatchAddon(index1, index2, e.target.value, 'name', item2.id, index1.addoncategoryId)} onChange={(e) => ChangeHandler(index1, index2, e.target.value, 'name')} value={item2.name} class="form-control"/>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-md-6">
                                                                                            <div class="form-group">
                                                                                                <label>Calories</label>
                                                                                                <input onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} type="number" min="1" max="100000" onBlur={(e) => PatchAddon(index1, index2, e.target.value, 'calories', item2.id, index1.addoncategoryId)} value={item2.calories} onChange={(e) => ChangeHandler(index1, index2, e.target.value, 'calories')} class="form-control" />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-md-6">
                                                                                            <div class="form-group">
                                                                                                <label>Cost</label>
                                                                                                <input onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} type="number" onBlur={(e) => PatchAddon(index1, index2, e.target.value, 'price', item2.id, index1.addoncategoryId)} onChange={(e) => ChangeHandler(index1, index2, e.target.value, 'price')} value={item2.price} class="form-control" />
                                                                                                </div>
                                                                                        </div>
                                                                                        <div class="col-md-6">
                                                                                            <div class="form-group">
                                                                                                <label>Removal Allowed</label>
                                                                                                <select onBlur={(e) => PatchAddon(index1, index2, e.target.value, 'required', item2.id, index1.addoncategoryId)} onChange={(e) => ChangeHandler(index1, index2, e.target.value, 'required')} class="form-control"  >
                                                                                                    <option value="true" selected={(item2.required==true)? 1 : 0}>Yes</option>
                                                                                                    <option value="false" selected={(item2.required==false)? 1 : 0}>No</option>
                                                                                                </select>
                                                                                                </div>
                                                                                        </div>
                                                                                        <div class="col-md-6">
                                                                                            <div class="form-group">
                                                                                                <label>Default?</label>
                                                                                                <select onBlur={(e) => PatchAddon(index1, index2, e.target.value, 'default', item2.id, index1.addoncategoryId)} onChange={(e) => ChangeHandler(index1, index2, e.target.value, 'default')} class="form-control" >
                                                                                                    <option value="false" selected={(item2.default==false)? 1 : 0}>No</option>
                                                                                                    <option value="true" selected={(item2.default==true)? 1 : 0}>Yes</option>
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                                {index2==item1.data.length-1 ?
                                                                                                <div class="col-md-6" style={{ "display": "none;" }}>
                                                                                                    <div class="form-group addon_addbtn">
                                                                                                        <label>&nbsp; </label>
                                                                                                        <button onClick={() => appendItem(index1, item1.addoncategoryId)} style={{ paddingLeft: '100px' }} class="btn_sty_1" style={{ "min-width": "110px;" }}>Add New Item</button>
                                                                                                    </div>
                                                                                                </div> : ''}
                                                                                    </div>
                                                                                </div>
                                                                            </section>
                                                                            )
                                                                        })
                                                                        : <div align="center" style={{ 'font-size': '16', 'paddingLeft': '800px' }} ></div>
                                                                } 
                                                        </section>
                                                        )
                                                    }
                                                    })
                                                    : <div align="center" style={{ 'font-size': '16', 'paddingLeft': '800px' }} ></div>
                                            }
                                        </form>
                                        <div class="text-center">
                                            <button onClick={() => appendCategoryWithAddOn()}  class="btn_sty_1" style={{ "min-width": "110px;" }} >Add New Category</button>
                                    </div>
                                    <a style={{ "text-decoration": "none", "color": "#000" }} href={'/product?id=' + props.location.state}>
                                        <img src="./back-arrow.png" /> Go Back
                                        <br /><br />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </body >
            </html >
        </div >
    )
}
export default CreateAddon;