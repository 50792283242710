import img1 from "../assets/img/bell.svg";
import img2 from "../assets/img/henry_solis.jpg";
import { useHistory } from 'react-router-dom';
import { authRoutes } from '../routes/AuthRoutes';
import * as URL from '../../src/services/ServiceConstant';
import apiRequest from "../services/ServiceRequest";
import React, { useState, useEffect } from 'react';
import { dynamicStylesheet } from '../utils/common';
import ReactDOM from 'react-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as EmailValidator from "email-validator";
import { Route, Link, BrowserRouter as Router } from 'react-router-dom'
export const LocationManagerSetUpPassword = () => {
    useEffect(async () => {
        let dynamicCSS = await dynamicStylesheet();
        var style = document.createElement('style');
        style.innerHTML = dynamicCSS.css;
        document.getElementsByTagName('head')[0].appendChild(style);
        var locationmanagerId = window.location.href.split('=')[1];
        var setup = window.location.href.includes('setup');
        var forgot = window.location.href.includes('forgot');
        const queryParameters = new URLSearchParams(window.location.search)
        locationmanagerId = queryParameters?.get("locationmanagerId");
        var link = queryParameters?.get("link");
        let emailLinkData = await apiRequest({
            link: link
        }, URL.BASE_URL + '/get-email-link', 'post');
        if (emailLinkData?.data.length > 0) {
            window.location.href = "/expired";
        }
        if (setup == true) {
            setSetUp(true);
            try {
                var response = await apiRequest([], URL.BASE_URL + '/LocationManagers?access_token=8zDfKkprzmmg0dpJi0HN8RVPOb5FuDF7e6Ts3401xzWBizSVXLVJtuREbamMSafG&filter={"where":{"id":"' + locationmanagerId + '"}}', 'get');
                if (response.data.password == undefined || response.data.password.trim() == '') {
                    setDisableForm(true);
                }
            } catch (err) {
                console.log(err);
            }
        } else if (forgot==true) {
            setForgot(true);
        }
    },[]);
    const [setup, setSetUp] = useState(false);
    const [forgot, setForgot] = useState(false);
    const [newpassword, setNewPassword] = useState("");
    const [confirmpassword, setConfirmPassword] = useState("");
    const [color1, setColor1] = useState("red");
    const [color2, setColor2] = useState("red");
    const [color3, setColor3] = useState("red");
    const [color4, setColor4] = useState("red");
    const [disable_form, setDisableForm] = useState(false);
    let history = useHistory();
    const checkPassword = (value, name) => {
        (name=="newpassword") ? setNewPassword(value) : setConfirmPassword(value);
        const uppercaseRegExp   = /(?=.*?[A-Z])/;
        const lowercaseRegExp   = /(?=.*?[a-z])/;
        const digitsRegExp      = /(?=.*?[0-9])/;
        const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
        const minLengthRegExp   = /.{8,}/;
        const passwordLength =      value.length;
        const uppercasePassword =   uppercaseRegExp.test(value);
        const lowercasePassword =   lowercaseRegExp.test(value);
        const digitsPassword =      digitsRegExp.test(value);
        const specialCharPassword = specialCharRegExp.test(value);
        const minLengthPassword =   minLengthRegExp.test(value);
        if(minLengthPassword==true){
            setColor1("green");
        }else{
            setColor1("red");
        }
        if(uppercasePassword==true || lowercasePassword==true){
            setColor2("green");
        }else{
            setColor2("red");
        }
        if(digitsPassword==true){
            setColor3("green");
        }else{
            setColor3("red");
        }
        if(specialCharPassword==true){
            setColor4("green");
        }else{
            setColor4("red");
        }
    }
    const UpdateLocationManagerPassword = async() => {
        if(color1!="green" || color2!="green" || color3!="green" || color4!="green"){
            alert("Invalid password, please setup password using password rules provided!!");
            return false;
        }else if(newpassword!=confirmpassword){
            alert("New Password and confirm Password do not match!!");
            return false;
        } else {
            const queryParameters = new URLSearchParams(window.location.search)
            var locationmanagerId = window.location.href.split('=')[2];
            var link = queryParameters.get("link");
            await apiRequest({
                link: link
            }, URL.BASE_URL + '/post-email-link', 'post');
            var response = await apiRequest({
                locationmanagerId: locationmanagerId,
                locationmanagerPassword : newpassword
            }, URL.BASE_URL + '/update-locationmanager-password', 'post');
            if (response?.data?.status == 1) {
                let locationmanagerData = await apiRequest([], URL.BASE_URL + '/get-locationmanager-data/' + locationmanagerId, 'get');
                var apiBody = {
                    "to": locationmanagerData.data.email,
                    "subject": "Password setup completed",
                    "html": "<h1>Congratulations, Your password setup was completed successfully!! Now you can login through the web portal and start receving the orders.</h1>"
                };
                let responseData = await apiRequest(apiBody, URL.BASE_URL + '/send-email', 'post');
                if (responseData) {
                    window.location.href = "locationmanager-success";   
                }
            }else{
                alert("Failed to setup password, please try again after a while!!");
                return false;
            }
        }
    }
    return (
        <html lang="en">
            <head>
                Food Delivery
                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                <link rel="shortcut icon" type="image/png" href="img/logo.png" />

                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
                <link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap" rel="stylesheet" />
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous" />
                <link rel="stylesheet" href="css/icofont.css" />
                <link rel="stylesheet" href="css/jquery.mCustomScrollbar.css" />
                <link rel="stylesheet" href="css/custom.css" />
                <title>Food Delivery</title>
            </head>
            <body>
                <div class="account_form">
                    
                        <div class="col-md-12 text-center">
                            <div class="account_icon_wrap">
                                <div class="account_icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32.602" height="43.469" viewBox="0 0 32.602 43.469">
                                        <path id="padlock" d="M31.526,16.3H30.168V10.867a10.867,10.867,0,0,0-21.734,0V16.3H7.075A4.08,4.08,0,0,0,3,20.376V39.394a4.08,4.08,0,0,0,4.075,4.075H31.526A4.08,4.08,0,0,0,35.6,39.394V20.376A4.08,4.08,0,0,0,31.526,16.3Zm-19.47-5.434a7.245,7.245,0,1,1,14.49,0V16.3H12.056Zm9.056,19.42v4.126a1.811,1.811,0,0,1-3.622,0V30.287a3.622,3.622,0,1,1,3.622,0Z" transform="translate(-3)" fill="#555" />
                                    </svg>
                                </div>
                            </div>
                        <h2 class="account_page_title">{setup == true ? 'Password Setup' : 'Forgot Password'}</h2>
                        
                            {disable_form==false ? <p class="forgot_pass_info">Congratulations for being added as location manager! Please use this page to create your new password and login into the web portal.</p> : <p class="forgot_pass_info">You have already used this link and setup your password. Please use the app and choose Forgot Password option if you wish to change your password.</p>}
                            
                        </div>
                        {disable_form==false ? 
                        <div>
                        <div class="col-md-12">
                            <li style={{"color" : color1}}>8 minimum characters</li>
                            <li style={{"color" : color2}}>Minimum 1 character</li>
                            <li style={{"color" : color3}}>Minimum 1 number</li>
                            <li style={{"color" : color4}}>Minimum 1 special character</li><br></br>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>New Password *</label>
                                <input type="password" name="newpassword" class="form-control" value={newpassword} onChange={e => checkPassword(e.target.value, e.target.name)} />
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Confirm Password *</label>
                                <input type="password" name="confirmpassword" class="form-control" value={confirmpassword} onChange={e => checkPassword(e.target.value, e.target.name)} />
                            </div>
                        </div>
                        
                        <div class="col-md-12">
                            <div class="form-group">
                                    <button onClick={() => UpdateLocationManagerPassword()} type="submit" class="btn_sty_1">Submit</button>
                            </div>
                        </div>
                        </div>
                         : ''}
                        
            
                </div>
                <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                <script src="js/jquery.mCustomScrollbar.concat.min.js"></script>
            </body>
        </html>
    );
}
export default LocationManagerSetUpPassword;