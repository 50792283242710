import axios from 'axios';
import * as URL from "../services/ServiceConstant";
import apiRequest from '../services/ServiceRequest';
const API_ENDPOINT = URL.BACKEND_ROOT;
export const orderCancelled_Admin = async(orderId) => {
    let emailTemplate = await apiRequest({emailtemplatetypeId : '631b2172ff2bb0af985d625a'}, API_ENDPOINT+'fetch-email-template-by-type','post');
    let order = await apiRequest({},API_ENDPOINT+'Orders?filter={"where":{"id":"'+orderId+'"},"include":["customer","address","drivers","location","orderDetails"]}','get');
    let emailMessage = emailTemplate.data[0].message;
    let detailsOfOrder = '';
    for(let i=0;i<order.data[0].orderDetails.length;i++){
        detailsOfOrder+="";
    }
    var variableObj = {
        "{customer name}":order?.data[0].customer.firstname+" "+order?.data[0].customer.lastname,
        "{business name}":order?.data[0].location.name,
        "{order details}":"Order Details Table",
        "{business address}" : order?.data[0].location.name,
        "{order id}" : order?.data[0].orderNo
    };
    emailMessage = emailMessage.replace(/{customer name}|{business name}|{order details}|{business address}|{order id}/gi, function(matched){
    return variableObj[matched];
    });
    await apiRequest({
        to : order?.data[0].customer.email,
        subject : 'Order Cancellation',
        html : emailMessage
    },API_ENDPOINT+'send-email','post');
}
export const productUpdated_LocationManager = async(data) => {
    let emailTemplate = await apiRequest({emailtemplatetypeId:'631b2172ff2bb0af985d6257'}, API_ENDPOINT+'fetch-email-template-by-type','post');
    let emailMessage = emailTemplate.data[0].message;
    var variableObj = {
        "{location manager name}" : data.location_manager_name,
        "{location name}" : data.location_name,
        "{product name}" : data.product_name,
        "{product id}" : data.product_id,
        "{product price}" : data.product_price
    };
    emailMessage = emailMessage.replace(/{location manager name}|{location name}|{product name}|{product id}|{category name}|{product price}/gi, function(matched){
        return variableObj[matched];
    });
    let projectSettings = await apiRequest({}, API_ENDPOINT +'projectSettings','get');
    await apiRequest({
        to: projectSettings.data.extracted[0].support_email,
        subject : 'Location manager updated product',
        html : emailMessage
    },API_ENDPOINT+'send-email','post');
}
export const profileUpdated_LocationManager = async(data) => {
    let emailTemplate = await apiRequest({emailtemplatetypeId:'631b2172ff2bb0af985d6255'}, API_ENDPOINT+'fetch-email-template-by-type','post');
    let emailMessage = emailTemplate.data[0].message;
    var variableObj = {
        "{location manager name}" : data.location_manager_name,
        "{location manager phone}" : data.location_manager_phone,
        "{location manager email}" : data.location_manager_email
    };
    emailMessage = emailMessage.replace(/{location manager name}|{location manager phone}|{location manager email}/gi, function(matched){
        return variableObj[matched];
    });
    let projectSettings = await apiRequest({}, API_ENDPOINT + 'projectSettings', 'get');
    await apiRequest({
        to: projectSettings.data.extracted[0].support_email,
        subject : 'Location manager updated profile',
        html : emailMessage
    },API_ENDPOINT+'send-email','post');
}
export const welcome_LocationManager = async (data) => {
    let emailTemplate = await apiRequest({ emailtemplatetypeId: '6374c68c16ab9d210ceeccf5' }, API_ENDPOINT + 'fetch-email-template-by-type', 'post');
    let projectSettings = await apiRequest({}, API_ENDPOINT +'projectSettings', 'get');
    let emailMessage = emailTemplate.data[0].message;
    let subject = emailTemplate.data[0].subject;
    var variableObj = {
        "{location manager password setup link}": data.location_manager_password_setip_link,
        "{business name}": projectSettings.data.extracted[0].business_name,
        "{business mobile}": projectSettings.data.extracted[0].support_email,
        "{business email}": projectSettings.data.extracted[0].support_phone
    };
    var subjectObj = {
        "{location manager name}": data.name
    };
    subject = subject.replace(/{location manager name}/gi, function (matched) {
        return subjectObj[matched];
    });
    emailMessage = emailMessage.replace(/{location manager password setup link}|{business name}|{business mobile}|{business email}/gi, function (matched) {
        return variableObj[matched];
    });
    await apiRequest({
        to: data.email,
        subject: subject,
        html: emailMessage
    }, API_ENDPOINT + 'send-email', 'post');
}
export const productDeleted_LocationManager = async (data) => {
    let emailTemplate = await apiRequest({ emailtemplatetypeId: '631b2172ff2bb0af985d6257' }, API_ENDPOINT + 'fetch-email-template-by-type', 'post');
    let emailMessage = `Location Manager ${data.username} has removed the product ${data.name} from locations`;
    var variableObj = {
        "{location manager name}": data.location_manager_name,
        "{location name}": data.location_name,
        "{product name}": data.product_name,
        "{product id}": data.product_id,
        "{product price}": data.product_price
    };
    emailMessage = emailMessage.replace(/{location manager name}|{location name}|{product name}|{product id}|{category name}|{product price}/gi, function (matched) {
        return variableObj[matched];
    });
    let projectSettings = await apiRequest({}, API_ENDPOINT + 'projectSettings', 'get');
    await apiRequest({
        to: projectSettings.data.extracted[0].support_email,
        subject: 'Location manager deleted product',
        html: emailMessage
    }, API_ENDPOINT + 'send-email', 'post');
}