import { Route, Redirect } from "react-router-dom";
import { getToken } from "../utils/common.js";
export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            const currentUser = getToken();
            if (!currentUser && props.location.pathname != "/forgotpassword") {
                return (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: props.location },
                        }}
                    />
                );
            }
            return <Component {...props} />;
        }}
    />
);
export default PrivateRoute;
