import './App.css';
import './media.css';
import { PrivateRoute } from "./routes/PrivateRoutes";
import { History } from "./helpers/History";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import indexRoutes from "./services/constants.js";
import Login from './components/login';
import SetUpPassword from './components/setup-password';
import LocationManagerSetUpPassword from './components/locationmanager-setup-password';
import BusinessOwnerSetUpPassword from './components/businessowner-setup-password';
import CustomerSetupPassword from "./components/customer-setup-passsword";
import Success from './components/driversuccess';
import LocationManagerSuccess from './components/locationmanagersuccess';
import BusinessOwnerSuccess from './components/businessownersuccess';
import CustomerSuccess from './components/customersuccess';
import Expired from './components/expired';
function App() {
  return (
    <Router history={History}>
      <Switch>
        {
          indexRoutes.map((prop, key) => {
            return (
              <PrivateRoute
                path={prop.path}
                key={key}
                component={prop.component}
              />
            )
          })
        }
        <Route exact path="/" component={Login} />
        <Route exact path="/setup-password" component={SetUpPassword} />
        <Route exact path="/location-manager-setup-password" component={LocationManagerSetUpPassword} />
        <Route exact path="/business-owner-setup-password" component={BusinessOwnerSetUpPassword} />
        <Route exact path="/forgot-password" component={SetUpPassword} />
        <Route exact path="/customer-setup-password" component={CustomerSetupPassword} />
        <Route exact path="/success" component={Success} />
        <Route exact path="/expired" component={Expired} />
        <Route exact path="/locationmanager-success" component={LocationManagerSuccess} />
        <Route exact path="/businessowner-success" component={BusinessOwnerSuccess} />
        <Route exact path="/customer-success" component={CustomerSuccess} />
      </Switch>
        </Router>
  )
}
export default App;

