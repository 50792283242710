import DeleteImg from "../svg/delete";
import PencilImg from "../svg/pencil";
function ResCategoriesList(props) {
    return (
        <div class="row">
            {
                props.values.data.length > 0
                    ? props.values.data?.map((item, index) => {
                        return (
                            <div class="col-md-6">
                                <div class="m_staff_box category_box">
                                    <div class="m_staff_top">
                                        <div class="img_box"><img src={item.multicategory.image} alt="" />
                                        </div>
                                        <div class="m_staff_info">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <h2 class="r_name">{item.multicategory.name}</h2>
                                                    <div class="category_bottom">
                                                        <label class="switch">
                                                            <input type="checkbox" disabled={false}
                                                                checked={props.values.get_data[index].multicategory.enable} value={item.multicategory.enable} onChange={e => props.values.handleCheckbox(item, index)} />
                                                            <span class="slider round"></span>
                                                        </label>
                                                        <div class="category_actions">
                                                            <ul>
                                                                <li data-toggle="modal" data-target="#edit_categoryModal">
                                                                    <button onClick={e => props.values.onEdit(item)}>
                                                                        {PencilImg()}
                                                                    </button>
                                                                </li>
                                                                <li>
                                                                    <button onClick={e => props.values.onDelete(item.id)}>
                                                                        {DeleteImg()}
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    : <div align="center" style={{ 'font-size': '16', 'paddingLeft': '800px' }} >{props.values.view_show_msg}</div>
            }
        </div>
    );
}
export default ResCategoriesList;