import { useEffect, useState } from "react";
import apiRequest from '../services/ServiceRequest';
import * as Url from '../../src/services/ServiceConstant';
import { toast } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";
import Header from "./header";
import Sidebar from "./menu"
import '../assets/css/media.css';
import Moment from 'react-moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../assets/css/pagination.css";
import { setLocalizationSettings } from '../utils/common.js';
const Alerts = (props) => {
    const [notifications, setNotifications] = useState([]);
    const [filter_notifications, setFilterNotifications] = useState([]);
    const [global_date, setGlobalDateFormat] = useState('');
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange; 
    toast.configure()
    const handleCallback = async (data) => {

    }
    useEffect(async () => {
        var localSettings = await setLocalizationSettings();
        await setGlobalDateFormat(localSettings?.[0]);
        var role = localStorage.getItem("role");
        var api_response = '';
        if (role == "businessowner") {
            api_response = await apiRequest([], Url.BASE_URL + '/Notifications?filter={"order":"id DESC","limit":"500","where":{"type":"web"}}', 'get');
        } else {
            api_response = await apiRequest([], Url.BASE_URL + '/Notifications?filter={"order":"id DESC","limit":"500","where":{"type":"web","role":"businessowner"}}', 'get');
        }
        var notifications = api_response.data;
        setNotifications(notifications);
        setFilterNotifications(notifications);
    }, []);
    // const dismissAlert = async(id) => {
    //     document.getElementById(id).style.display = 'none';
    //     await apiRequest({
    //         id : id,
    //         status : "terminated"
    //     }, Url.BASE_URL+'/Notifications','patch');
    // }
    const [currentPage, setcurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(10);
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
    const handleClick = (event) => {
        setcurrentPage(Number(event.target.id));
    };
    const pages = [];
    for (let i = 1; i <= Math.ceil(notifications.length / itemsPerPage); i++) {
        pages.push(i);
    }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filter_notifications.slice(indexOfFirstItem, indexOfLastItem);
    const renderPageNumbers = pages.map((number) => {
        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
            return (
                <li
                    key={number}
                    id={number}
                    onClick={handleClick}
                    className={currentPage == number ? "active" : null}
                >
                    {number}
                </li>
            );
        } else {
            return null;
        }
    });
    const handleNextbtn = () => {
        setcurrentPage(currentPage + 1);
        if (currentPage + 1 > maxPageNumberLimit) {
            setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    };
    const handlePrevbtn = () => {
        setcurrentPage(currentPage - 1);
        if ((currentPage - 1) % pageNumberLimit == 0) {
            setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
    };
    let pageIncrementBtn = null;
    if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
    }
    let pageDecrementBtn = null;
    if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
    }
    // const handleLoadMore = () => {
    //     setitemsPerPage(itemsPerPage + 5);
    // };
    const filterDateRange = async (daterange) => {
        var range_data = [];
        let mindate = new Date(daterange[0]);
        let maxdate = new Date(daterange[1]);
        mindate.setHours(0, 0, 0, 0)
        maxdate.setHours(0, 0, 0, 0);
        let NOTIFICATIONS = [...notifications]
        NOTIFICATIONS.forEach(function (p, i) {
            if (p.datetime != null && p.datetime != undefined) {
                let d = new Date(p.datetime);
                d.setHours(0, 0, 0, 0);
                if (d >= mindate) {
                    if (maxdate >= d) {
                        range_data.push(p)
                    }
                }
            }
        })
        setFilterNotifications(range_data);
    }
    return (
        <div>
            <html lang="en">
                <div class="sticky">
                    <Header indicator={notifications} parentCallback={(params) => handleCallback(params)} />
                </div>
                <body>
                    <div class="middle_block">
                        <Sidebar />
                        <div class="main_content" >
                            <br></br>
                            <div class="col-md-12">
                                <div class="s_date_wrap mng-calendar form-control" style={{
                                    alignSelf: 'center', width: '100%',
                                    display: 'flex',
                                    justifyContent: 'spaceBetween',
                                    alignItems: 'center'
                                }} >
                                    <DatePicker
                                        maxDate={new Date()}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        className='order-date-picker-css'
                                        placeholderText="Apply the Date Range"
                                        selectsRange={true}
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={(update) => {
                                            console.log(update);
                                            if (update[0] == null && update[1] == null) {
                                                console.log("one");
                                                window.location.reload();
                                            }
                                            setDateRange(update);
                                            var strToDate = new Date(dateRange[0]);
                                            if (update.length == 2 && update[0] != null && update[1] != null) {
                                                console.log("two");
                                                filterDateRange(update)
                                                return
                                            }
                                        }}
                                    />
                                    <i class="icofont-ui-calendar calendar_icon" style={{ paddingLeft: 10, "margin": "-4px -130px 7px 5px" }} htmlFor='fromdate'></i>
                                </div>
                            </div>
                            <div class="container-fluid">
                                <div class="alert-info-section">
                                    {
                                        currentItems.length > 0
                                            ? currentItems?.map((item, index) => {
                                                return (
                                                    <div style={{ "display": "flex" }} id={item?.id} class="alert alert-info fade in alert-dismissible show welcome_message">
                                                        {/* <button id="" onClick={() => dismissAlert(item?.id)} type="button" class="close" data-dismiss="alert" aria-label="Close" style={{ "display": "flex" }}>
                                            <span aria-hidden="true" style={{ 'font-size': '20px' }}>×</span>
                                        </button> */}
                                                        <b style={{ "width": "80%" }}>{item?.message}</b> <Moment format={global_date ? global_date : " DD MMM  YYYY-h:mm A"}>{item.datetime}</Moment><strong></strong>
                                                    </div>)
                                            }) : <div align="center" style={{ 'font-size': '16' }} >No notifications</div>
                                    }
                                </div>
                            </div>
                            <script type="text/javascript" src="https://maps.googleapis.com/maps/api/js?sensor=false"></script>
                            <script type="text/javascript" src="js/jquery-1.8.3.min.js"></script>
                            <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                            <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                            <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                            <script src="js/jquery.mCustomScrollbar.concat.min.js"></script>
                            <script>
                            </script>
                        </div>
                    </div>
                </body>
            </html >
            <ul className="pageNumbers pagination">
                <li onClick={handlePrevbtn}
                    className={currentPage == pages[0] ? "disallow" : "allow"}>
                    {"<"}
                </li>
                {pageDecrementBtn}
                {renderPageNumbers}
                {pageIncrementBtn}
                <li onClick={handleNextbtn}
                    className={currentPage == pages[pages.length - 1] ? "disallow" : "allow"}>
                    {">"}
                </li>
            </ul>
        </div>
    )
}
export default Alerts;