import PlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByPlaceId } from "react-places-autocomplete";
import CreditCardInput from 'react-credit-card-input';
export const getPlacesAutoComplete = (properties) => {
    return (
        <PlacesAutocomplete
            searchOptions={
                properties.searchOptions
            }
            value={properties.address}
            onChange={properties.setAddress}
            onSelect={properties.handleSelect}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                    {properties.setLat(properties.coordinates.lat)}
                    {properties.setLng(properties.coordinates.lng)}
                    <input className="form-control" {...getInputProps({ placeholder: "New Address" })} />
                    <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map(suggestion => {
                            const className = suggestion.active
                                ? 'suggestion-item--active shadow bg-white rounded'
                                : 'suggestion-item shadow bg-white rounded';
                            const style = suggestion.active
                                ? { backgroundColor: '#e9ecef', cursor: 'pointer', width: '238px' }
                                : { padding: '3px', backgroundColor: '#e9ecef', "box-shadow": "1px 1px #e9ecef", cursor: 'pointer', "margin": "0px", "border": "1px solid #e9ecef", "border-radius": "1px", width: '238px' }
                            return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                    })}
                                >
                                    <span><svg xmlns="http://www.w3.org/2000/svg" width="22" height="12" viewBox="0 0 14.992 19.989">
                                        <g id="placeholder" transform="translate(-64)">
                                            <g id="Group_6964" data-name="Group 6964" transform="translate(64)">
                                                <g id="Group_6963" data-name="Group 6963">
                                                    <path id="Path_3633" data-name="Path 3633" d="M71.5,0A7.5,7.5,0,0,0,64,7.5c0,5.2,6.766,12.022,7.054,12.311a.627.627,0,0,0,.885,0c.287-.289,7.054-7.115,7.054-12.311A7.5,7.5,0,0,0,71.5,0Zm0,18.461c-1.492-1.6-6.247-7-6.247-10.965a6.247,6.247,0,0,1,12.493,0C77.743,11.461,72.988,16.865,71.5,18.461Z" transform="translate(-64)" />
                                                </g>
                                            </g>
                                            <g id="Group_6966" data-name="Group 6966" transform="translate(67.748 3.748)">
                                                <g id="Group_6965" data-name="Group 6965">
                                                    <path id="Path_3634" data-name="Path 3634" d="M163.748,96a3.748,3.748,0,1,0,3.748,3.748A3.753,3.753,0,0,0,163.748,96Zm0,6.247a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,163.748,102.247Z" transform="translate(-160 -96)" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>{suggestion.description}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    );
}
export const getPaymentSection = (properties) => {
    return (
        <div>
            <h1 class="manual_order_titles paymentheading">
                <i class="far fa-credit-card"></i> Payment Information
            </h1><br /><br />
            <div style={{ "display": properties.payment_block_style }} class="">
                <input value={properties.card_holder_name} onChange={(e) => properties.setCardHolderName(e.target.value)} type="text" placeholder="Card Holder Name.." class="form-control" />
            </div>
            <br />
            <CreditCardInput
                containerClassName="mo_card_information"
                cardCVCInputRenderer={({ handleCardCVCChange, props }) => (
                    <input
                        style={{ "display": properties.payment_block_style }}
                        {...props}
                        onChange={handleCardCVCChange(e => {
                            properties.setCardCV(e.target.value);
                        })}
                    />
                )}
                cardExpiryInputRenderer={({ handleCardExpiryChange, props }) => (
                    <input
                        style={{ "display": properties.payment_block_style }}
                        {...props}
                        onChange={handleCardExpiryChange(e => {
                            properties.setCardExpiry(e.target.value);
                        }
                        )}
                    />
                )}
                cardNumberInputRenderer={({ handleCardNumberChange, props }) => (
                    <input
                        style={{ "display": properties.payment_block_style }}
                        {...props}
                        onChange={handleCardNumberChange(e => {
                            properties.setCardNumber(e.target.value)
                        }
                        )}
                    />
                )}
            />
            <p>{properties.payment_help_text}</p>
        </div>
    );
}