import { useState, useEffect } from "react";
import Header from "./header"
import Sidebar from "./menu"
import * as Url from '../../src/services/ServiceConstant';
import apiRequest from "../services/ServiceRequest";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUser } from "../utils/common";
import BusinessProfile from "./businessprofile";

const Settings = (props) => {

    toast.configure()
    var user = getUser();
    const [settings, setSettings] = useState("");
    const [pickup, setpickup] = useState("");
    const [delivery, setdelivery] = useState("");
    const [orders, setorders] = useState("");
    const [infolinking, setinfolinks] = useState("");
    const [selfsign, setselfsign] = useState("");
    const [notifications, setNotifications] = useState("");
    const [payments, setpayments] = useState("");
    const [view_show_msg, show_mesage] = useState("");
    const [radius, setRadius] = useState("");
    const [charges, setCharges] = useState("");
    const [eta, setETA] = useState("");
    const [charges_type, setChargesType] = useState("");
    const [charges_type_id, setChargesTypeId] = useState("");
    const [current_password, setCurrentPassword] = useState("");
    const [new_password, setNewPassword] = useState("");
    const [confirm_password, setConfirmPassword] = useState("");
    const [locations, setLocations] = useState([]);
    const setPassword = async (text, type) => {
        if (type == "current") {
            setCurrentPassword(text);
        }
        else if (type == "new") {
            setNewPassword(text);
        }
        else if (type == "confirm") {
            setConfirmPassword(text);
        }
    }
    const getSettings = async () => {
        var get_products = await apiRequest([], Url.SETTINGS, 'get')
        if (get_products == null || get_products == undefined || get_products.data === '{}' || Object.entries(get_products.data).length === 0 || get_products === null || get_products === undefined || get_products.length <= 0 || get_products.data.length <= 0) {
            show_mesage("No records found")
            setSettings("")
        }
        else {
            var locations = await apiRequest([], Url.BASE_URL + '/Locations?filter={"where":{"enable":' + true + ',"id_deleted":' + false + '}}', 'get');
            setLocations(locations.data);
            var pickup = [];
            var delivery = [];
            var orders = [];
            var payments = [];
            var infolinks = [];
            var selfsignup = [];
            var notifications = [];
            var lengthofarray = get_products.data.length;
            for (var i = 0; i < lengthofarray; i++) {
                if (get_products.data[i].name == "Allow Pickups") {
                    var allowpickup = get_products.data[i];
                    allowpickup.settingtype = "pickup";
                    pickup.push(allowpickup);
                }
                if (get_products.data[i].name == "Curbside Pickup") {
                    var curbsidepickup = get_products.data[i];
                    allowpickup.settingtype = "pickup";
                    pickup.push(curbsidepickup);
                }
                if (get_products.data[i].name == "Allow Deliveries") {
                    var del = get_products.data[i];
                    del.settingtype = "delivery";
                    delivery.push(del);
                }
                if (get_products.data[i].name == "Auto Acceptance") {
                    get_products.data[i].name = "Auto Assignment";
                    var auto_orders = get_products.data[i];
                    orders.push(auto_orders);
                }
                // if (get_products.data[i].name == "Allow UPI") {
                //     var allowpaymentsupi = get_products.data[i];
                //     payments.push(allowpaymentsupi);
                // }
                if (get_products.data[i].name == "About Us") {
                    var aboutus = get_products.data[i];
                    infolinks.push(aboutus);
                }
                if (get_products.data[i].name == "Terms and Conditions") {
                    var terms = get_products.data[i];
                    infolinks.push(terms);
                }
                if (get_products.data[i].name == "FAQS") {
                    var faq = get_products.data[i];
                    infolinks.push(faq);
                }
                if (get_products.data[i].name == "Self Signup") {
                    var ss = get_products.data[i];
                    selfsignup.push(ss);
                }
                if (get_products.data[i].name == "Max No of orders Allowed") {
                    var ss = get_products.data[i];
                    selfsignup.push(ss);
                }
                if (get_products.data[i].name == "Display on Map") {
                    get_products.data[i].type = "text";
                    var ss = get_products.data[i];
                    selfsignup.push(ss);
                }
                if (get_products.data[i].name == "Web Notifications") {
                    var ss = get_products.data[i];
                    notifications.push(ss);
                }
            }
            setpickup(pickup);
            setdelivery(delivery);
            setorders(orders);
            setpayments(payments);
            setinfolinks(infolinks);
            setselfsign(selfsignup);
            setNotifications(notifications);
            let temp_Product = get_products.data;
            setSettings(temp_Product)
            setETA(temp_Product[8].value)
            setChargesType(temp_Product[9].value)
            setCharges(temp_Product[10].value)
            setRadius(temp_Product[11].value)
            setChargesTypeId(temp_Product[9].id)
        }
    }
    const notify = (message) => {
        toast.success(message)
    }
    const errornotify = (message) => {
        toast.error(message)
    }
    const PasswordForm = () => {
        return (
            <div class="change_password">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="heading_settings">Change Password</h2>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Current Password</label>
                            <input type="password" value={current_password} onChange={(e) => setPassword(e.target.value, "current")} class="form-control" />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>New Password</label>
                            <input type="password" value={new_password} onChange={(e) => setPassword(e.target.value, "new")} class="form-control" />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Confirm New Password</label>
                            <input type="password" value={confirm_password} onChange={(e) => setPassword(e.target.value, "confirm")} class="form-control" />
                        </div>
                    </div>

                    <div class="col-md-12">
                        <button onClick={() => ChangePassword(user, current_password, new_password, confirm_password)} class="btn_sty_1">Change Password</button>
                    </div>
                </div>
            </div>
        );
    }
    const onChangeValue = async (type, value) => {
        if (type == "eta") {
            let s = {
                "id": value,
                "value": eta
            }
            let add_response = await apiRequest(s, Url.SETTINGS, 'post')
            if (add_response.error) {
                errornotify("'Failed', response.error.message");
                return;
            }
            else {
                notify("Updated successfully")
            }
        }
        else if (type == "maxorders") {
            let s = {
                "id": value,
                "value": selfsign?.[1]?.value
            }
            let add_response = await apiRequest(s, Url.SETTINGS, 'post')
            if (add_response.error) {
                errornotify("'Failed', response.error.message");
                return;
            }
            else {
                notify("Updated successfully")
            }
        }
        else if (type == "displaymap") {
            let s = {
                "id": value,
                "value": selfsign?.[2]?.value
            }
            let add_response = await apiRequest(s, Url.SETTINGS, 'post')
            if (add_response.error) {
                errornotify("'Failed', response.error.message");
                return;
            }
            else {
                notify("Updated successfully")
            }
        }
        else if (type == "charges") {
            if (charges_type != "") {
                let st = {
                    "id": value,
                    "value": charges
                }
                let charges_types = []
                charges_types.push(charges_type)
                let sp = {
                    "id": charges_type_id,
                    "value": charges_type
                }
                let add_response = await apiRequest(st, Url.SETTINGS, 'post')
                let data_response = await apiRequest(sp, Url.SETTINGS, 'post')
                if (add_response.error) {
                    errornotify("'Failed', response.error.message");
                    return;
                }
                else {
                    notify("Updated successfully")
                }
            }
        }
        else if (type == "radius") {
            let s = {
                "id": value,
                "value": radius
            }
            let add_response = await apiRequest(s, Url.SETTINGS, 'post')
            if (add_response.error) {
                errornotify("'Failed', response.error.message");
                return;
            }
            else {
                notify("Updated successfully")
            }
        }
        await apiRequest({}, Url.BASE_URL + '/sendSettingsUpdateToAllDevices', 'get');
    }
    const handleCheckbox = async (item, index) => {
        if (item.name == "Allow Pickups") {
            if (item.enabled == true) {
                var myBoolean = document.getElementById("60ffdb515e29402fa0bbf376").value === "false";
                if (myBoolean) {
                    alert("Allow Deliveries or Allow Pickups, One of these must be enabled");
                    return false;
                }
            }
        }
        if (item.name == "Allow Deliveries") {
            if (item.enabled == true) {
                var myBoolean = document.getElementById("60ffdb515e29402fa0bbf370").value === "false";
                if (myBoolean) {
                    alert("Allow Deliveries or Allow Pickups, One of these must be enabled");
                    return false;
                }
            }
        }
        await ChangeEnableStatus(item);
        getSettings();
    }
    async function ChangeEnableStatus(item) {
        let temp = [...settings]
        if (item.enabled === false) {
            let ss = true
            item.enabled = ss
            let add_response = await apiRequest(item, Url.SETTINGS, 'post')
            if (add_response.error) {
                errornotify("'Failed', response.error.message");
                return;
            } else {
                setSettings(temp)
                getSettings();
            }
        }
        else {
            let st = false
            item.enabled = st
            let add_response = await apiRequest(item, Url.SETTINGS, 'post')
            if (add_response.error) {
                errornotify("'Failed', response.error.message");
                return;
            } else {
                setSettings(temp)
                getSettings();
            }
        }
        await apiRequest({}, Url.BASE_URL + '/sendSettingsUpdateToAllDevices', 'get');
    }
    const handleCallback = async (childData) => {

    }
    useEffect(async () => {
        getSettings();
    }, []);
    const ChangePassword = async (id, current_password, new_password, confirm_password) => {
        let url = '';
        if (localStorage.getItem("role") == "businessowner") {
            url = Url.BASE_URL + '/BusinessOwners/change-password';
        } else {
            url = Url.BASE_URL + '/LocationManagers/change-password'
        }
        if (current_password == "" || new_password == "" || confirm_password == "") {
            errornotify("password fields are mandatory!!");
            return false;
        }
        if (new_password != confirm_password) {
            errornotify("new and confirm passwords do not match!!");
            return false;
        }
        let changePassword = await apiRequest({
            oldPassword: current_password,
            newPassword: new_password
        }, url, 'post');
        if (changePassword == undefined) {
            errornotify("old password is incorrect!!");
        } else {
            notify("password changed successfully!!");
        }
    }
    if (localStorage.getItem("role") == "businessowner") {
        return (
            <div>
                <html lang="en">
                    <div class="sticky">
                        <Header parentCallback={handleCallback} />
                    </div>
                    <body>
                        <div class="middle_block">
                            <Sidebar tabvalue='staff' />
                            <div class="main_content">
                                <div class="container-fluid">
                                    <div class="settings_page">
                                        <h4>Settings</h4>
                                        <hr></hr>
                                        {PasswordForm()}
                                        <hr></hr>
                                        <BusinessProfile />
                                        <h4>Info Links</h4>
                                        <div class="row">
                                            {infolinking.length > 0
                                                ? infolinking?.map((item, index) => {
                                                    return (
                                                        <div class="col-md-4">
                                                            <div class="toggle_block">
                                                                <h3>{item.name}</h3>
                                                                <label class="switch">
                                                                    <input type="checkbox" disabled={false}
                                                                        checked={item.enabled} value={item.enabled} onChange={e => handleCheckbox(item, index)} />
                                                                    <span class="slider round"></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                : <div align="center" style={{ 'font-size': '16', 'paddingLeft': '800px' }} >{view_show_msg}</div>
                                            }
                                        </div>
                                        <hr></hr>
                                        <h4>Drivers</h4>
                                        <div class="row">
                                            {selfsign.length > 0
                                                ? selfsign?.map((item, index) => {
                                                    return (
                                                        (item.type == "boolean") ?
                                                            <div class="col-md-4">
                                                                <div class="toggle_block">
                                                                    <h3>{item.name}</h3>
                                                                    <label class="switch">
                                                                        <input type="checkbox" disabled={false}
                                                                            checked={item.enabled} value={item.enabled} onChange={e => handleCheckbox(item, index)} />
                                                                        <span class="slider round"></span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            :
                                                            ""
                                                    )
                                                })
                                                : <div align="center" style={{ 'font-size': '16', 'paddingLeft': '800px' }} >{view_show_msg}</div>
                                            }
                                            <>
                                                <div class="col-md-4">
                                                    <div class="toggle_block">
                                                        <h3>{selfsign?.[1]?.name}</h3>
                                                        <input type="text" class="form-control" value={selfsign?.[1]?.value} onChange={(e) => {
                                                            let items = [...selfsign];
                                                            let item = { ...items[1] };
                                                            item.value = e.target.value;
                                                            items[1] = item;
                                                            setselfsign(items);
                                                        }} onBlur={() => onChangeValue("maxorders", settings[17].id)} />

                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="toggle_block">
                                                        <h3>{selfsign?.[2]?.name}</h3>
                                                        <input type="text" class="form-control" value={selfsign?.[2]?.value} onChange={(e) => {
                                                            let items = [...selfsign];
                                                            let item = { ...items[2] };
                                                            item.value = e.target.value;
                                                            items[2] = item;
                                                            setselfsign(items);
                                                        }} onBlur={() => onChangeValue("displaymap", settings[19].id)} />

                                                    </div>
                                                </div>
                                            </>
                                        </div>
                                        <hr></hr>
                                        <h4>Alerts</h4>
                                        <div class="row">
                                            {notifications.length > 0
                                                ? notifications?.map((item, index) => {
                                                    return (
                                                        (item.type == "boolean") ?
                                                            <div class="col-md-4">
                                                                <div class="toggle_block">
                                                                    <h3>{item.name}</h3>
                                                                    <label class="switch">
                                                                        <input type="checkbox" disabled={false}
                                                                            checked={item.enabled} value={item.enabled} onChange={e => handleCheckbox(item, index)} />
                                                                        <span class="slider round"></span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            :
                                                            ''
                                                    )
                                                })
                                                : <div align="center" style={{ 'font-size': '16', 'paddingLeft': '800px' }} >{view_show_msg}</div>
                                            }
                                        </div>
                                        <hr></hr>
                                        {
                                            localStorage.getItem("multi_restaurant") == 'true' ?
                                        
                                                <>
                                                    <h4>Restaurant Settings</h4>
                                                    <div class="col-md-4">
                                                    <select class="form-control">
                                                           
                                                            {locations?.length > 0 ? 
                                                                locations?.map(function (item, index) {
                                                                    return (
                                                                        <option value={item.id}>{item.name}</option>
                                                                    );
                                                                })  : ""  
                                                            }
                                                            
                                                        </select>
                                                    </div>
                                                    <hr></hr>
                                                    {/* <h4>Payments</h4> */}
                                                    <div class="row">
                                                        {payments.length > 0
                                                            ? payments?.map((item, index) => {
                                                                return (
                                                                    <div class="col-md-4">
                                                                        <div class="toggle_block">
                                                                            <h3>{item.name}</h3>
                                                                            <label class="switch">
                                                                                <input type="checkbox" disabled={false}
                                                                                    checked={item.enabled} value={item.enabled} onChange={e => handleCheckbox(item, index)} />
                                                                                <span class="slider round"></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            : <div align="center" style={{ 'font-size': '16', 'paddingLeft': '800px' }} >{view_show_msg}</div>
                                                        }
                                                    </div>
                                                    <hr></hr>
                                        
                                                    <h4>Orders</h4>
                                                    <div class="row">
                                                        {orders.length > 0
                                                            ? orders?.map((item, index) => {
                                                                return (
                                                                    <div class="col-md-4">
                                                                        <div class="toggle_block">
                                                                            <h3>{item.name}</h3>
                                                                            <label class="switch">

                                                                                <input type="checkbox" disabled={false}
                                                                                    checked={item.enabled} value={item.enabled} onChange={e => handleCheckbox(item, index)} />
                                                                                <span class="slider round"></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            : <div align="center" style={{ 'font-size': '16', 'paddingLeft': '800px' }} >{view_show_msg}</div>
                                                        }
                                                        <div class="col-md-4 toggle_block">
                                                            <div class="form-group">
                                                                <label>Current ETA</label>
                                                                <input type="text" class="form-control" value={eta} onChange={(e) => { setETA(e.target.value) }} onBlur={() => onChangeValue("eta", settings[8].id)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr></hr>
                                                    <h4>Pickup</h4>
                                                    <div class="row">
                                                        {pickup.length > 0
                                                            ? pickup?.map((item, index) => {
                                                                return (
                                                                    <div class="col-md-4">
                                                                        <div class="toggle_block">
                                                                            <h3>{item.name}</h3>
                                                                            <label class="switch">

                                                                                <input id={item.id} type="checkbox" disabled={false}
                                                                                    checked={item.enabled} value={item.enabled} onChange={e => handleCheckbox(item, index)} />
                                                                                <span class="slider round"></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            : <div align="center" style={{ 'font-size': '16', 'paddingLeft': '800px' }} >{view_show_msg}</div>
                                                        }
                                                    </div>
                                                    <hr></hr>
                                                    <h4>Delivery</h4>
                                                    <div class="row">
                                                        {delivery.length > 0
                                                            ? delivery?.map((item, index) => {
                                                                return (
                                                                    <div class="col-md-3">
                                                                        <div class="toggle_block">
                                                                            <h3>{item.name}</h3>
                                                                            <label class="switch">

                                                                                <input id={item.id} type="checkbox" disabled={false}
                                                                                    checked={item.enabled} value={item.enabled} onChange={e => handleCheckbox(item, index)} />
                                                                                <span class="slider round"></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            : <div align="center" style={{ 'font-size': '16', 'paddingLeft': '800px' }} >{view_show_msg}</div>
                                                        }
                                                        {(settings.length > 0) ?
                                                            <div style={{ "display": "flex" }} class="col-md-9">
                                                                <div class="col-md-4 toggle_block">
                                                                    <div class="form-group">
                                                                        <label>Delivery charge</label>
                                                                        <div class="input-group">
                                                                            <div class="input-group-prepend</>">
                                                                                <select class="form-control" value={charges_type} onChange={(e) => { setChargesType(e.target.value) }} onBlur={() => onChangeValue("chargestype", settings[9].id)}>
                                                                                    <option value="fixed">Fixed</option>
                                                                                    <option value="percent">Percent</option>
                                                                                </select>
                                                                            </div>
                                                                            <input type="text" class="form-control" value={charges} onChange={(e) => { setCharges(e.target.value) }} onBlur={() => onChangeValue("charges", settings[10].id)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div style={{ "margin-left": "20px" }} class="col-md-5 toggle_block">
                                                                    <div class="form-group">
                                                                        <label>Delivery Radius (Miles)</label>
                                                                        <input type="text" class="form-control" value={radius} onChange={(e) => { setRadius(e.target.value) }} onBlur={() => onChangeValue("radius", settings[11].id)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : null}
                                                    </div>
                                                    <hr></hr>
                                                </> : ""}
                                        
                                        
                                        
                                        
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </body >
                </html >
            </div >
        )
    } else {
        return (
            <div>
                <html lang="en">
                    <div class="sticky">
                        <Header parentCallback={handleCallback} />
                    </div>
                    <body>
                        <div class="middle_block">
                            <Sidebar tabvalue='staff' />
                            <div class="main_content">
                                <div class="container-fluid">
                                    <div class="settings_page">
                                        <h4>Settings</h4>
                                        <hr></hr>
                                        {PasswordForm()}
                                        <hr></hr>
                                        
                                    
                                        {
                                            localStorage.getItem("multi_restaurant") == 'true' ?

                                                <>
                                                    <h4>Restaurant Settings</h4>
                                                    <hr></hr>
                                                    {/* <h4>Payments</h4> */}
                                                    <div class="row">
                                                        {payments.length > 0
                                                            ? payments?.map((item, index) => {
                                                                return (
                                                                    <div class="col-md-4">
                                                                        <div class="toggle_block">
                                                                            <h3>{item.name}</h3>
                                                                            <label class="switch">
                                                                                <input type="checkbox" disabled={false}
                                                                                    checked={item.enabled} value={item.enabled} onChange={e => handleCheckbox(item, index)} />
                                                                                <span class="slider round"></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            : <div align="center" style={{ 'font-size': '16', 'paddingLeft': '800px' }} >{view_show_msg}</div>
                                                        }
                                                    </div>
                                                    <hr></hr>

                                                    <h4>Orders</h4>
                                                    <div class="row">
                                                        {orders.length > 0
                                                            ? orders?.map((item, index) => {
                                                                return (
                                                                    <div class="col-md-4">
                                                                        <div class="toggle_block">
                                                                            <h3>{item.name}</h3>
                                                                            <label class="switch">

                                                                                <input type="checkbox" disabled={false}
                                                                                    checked={item.enabled} value={item.enabled} onChange={e => handleCheckbox(item, index)} />
                                                                                <span class="slider round"></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            : <div align="center" style={{ 'font-size': '16', 'paddingLeft': '800px' }} >{view_show_msg}</div>
                                                        }
                                                        <div class="col-md-4 toggle_block">
                                                            <div class="form-group">
                                                                <label>Current ETA</label>
                                                                <input type="text" class="form-control" value={eta} onChange={(e) => { setETA(e.target.value) }} onBlur={() => onChangeValue("eta", settings[8].id)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr></hr>
                                                    <h4>Pickup</h4>
                                                    <div class="row">
                                                        {pickup.length > 0
                                                            ? pickup?.map((item, index) => {
                                                                return (
                                                                    <div class="col-md-4">
                                                                        <div class="toggle_block">
                                                                            <h3>{item.name}</h3>
                                                                            <label class="switch">

                                                                                <input id={item.id} type="checkbox" disabled={false}
                                                                                    checked={item.enabled} value={item.enabled} onChange={e => handleCheckbox(item, index)} />
                                                                                <span class="slider round"></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            : <div align="center" style={{ 'font-size': '16', 'paddingLeft': '800px' }} >{view_show_msg}</div>
                                                        }
                                                    </div>
                                                    <hr></hr>
                                                    <h4>Delivery</h4>
                                                    <div class="row">
                                                        {delivery.length > 0
                                                            ? delivery?.map((item, index) => {
                                                                return (
                                                                    <div class="col-md-3">
                                                                        <div class="toggle_block">
                                                                            <h3>{item.name}</h3>
                                                                            <label class="switch">

                                                                                <input id={item.id} type="checkbox" disabled={false}
                                                                                    checked={item.enabled} value={item.enabled} onChange={e => handleCheckbox(item, index)} />
                                                                                <span class="slider round"></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            : <div align="center" style={{ 'font-size': '16', 'paddingLeft': '800px' }} >{view_show_msg}</div>
                                                        }
                                                        {(settings.length > 0) ?
                                                            <div style={{ "display": "flex" }} class="col-md-9">
                                                                <div class="col-md-4 toggle_block">
                                                                    <div class="form-group">
                                                                        <label>Delivery charge</label>
                                                                        <div class="input-group">
                                                                            <div class="input-group-prepend</>">
                                                                                <select class="form-control" value={charges_type} onChange={(e) => { setChargesType(e.target.value) }} onBlur={() => onChangeValue("chargestype", settings[9].id)}>
                                                                                    <option value="fixed">Fixed</option>
                                                                                    <option value="percent">Percent</option>
                                                                                </select>
                                                                            </div>
                                                                            <input type="text" class="form-control" value={charges} onChange={(e) => { setCharges(e.target.value) }} onBlur={() => onChangeValue("charges", settings[10].id)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div style={{ "margin-left": "20px" }} class="col-md-5 toggle_block">
                                                                    <div class="form-group">
                                                                        <label>Delivery Radius (Miles)</label>
                                                                        <input type="text" class="form-control" value={radius} onChange={(e) => { setRadius(e.target.value) }} onBlur={() => onChangeValue("radius", settings[11].id)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : null}
                                                    </div>
                                                    <hr></hr>
                                                </> : ""}





                                    </div>
                                </div>
                            </div>
                        </div>
                    </body >
                </html >
            </div >
        );
    }
}
export default Settings;