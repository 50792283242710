import React, { useState, useEffect } from "react";
import Header from "./header"
import Sidebar from "./menu"
import * as Url from '../../src/services/ServiceConstant';
import apiRequest from "../services/ServiceRequest";
import "../assets/css/pagination.css";
import DatePicker from "react-datepicker";
import 'react-toastify/dist/ReactToastify.css';
import { ExportJsonCsv } from 'react-export-json-csv';
import { setLocalizationSettings, LoaderGif, dynamicStylesheet } from '../utils/common.js';
import { Modal } from 'react-bootstrap';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import TransactionList from "../components/list/transactions";
import '../../src/assets/css/transaction_invoice.css'
import TransactionInvoice from "./transaction_invoice";
import moment from 'moment'
const Transactions = (props) => {
    const printRef = React.useRef();
    const [loading, setLoading] = useState(true);
    const [transactions, setTransactions] = useState("");
    const [transaction_data, setTransactionsData] = useState("");
    const [filtered_transactions, setFilteredTransactions] = useState("");
    const [view_show_msg, show_mesage] = useState("");
    const [headers, setHeaders] = useState("");
    const [data, setData] = useState("");
    const [delivery_charge, setDeliveryCharge] = useState("");
    const [global_date, setGlobalDateFormat] = useState('');
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    var range_data = []
    const [currentPage, setcurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(20);
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
    const [driverInfo, setdriverInfo] = useState([]);
    const [order_detail, setOrderDetail] = useState(false);
    const [show, setShow] = useState(false);
    const [orderId, setOrderId] = useState("");
    const [item_total, setItemTotal] = useState("");
    const [order_total, setOrderTotal] = useState("");
    const [tax_total, setTaxTotal] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [address_name, setAddressName] = useState("");
    const [type, setType] = useState("");
    const [orderno, setOrderNo] = useState("");
    const [sheduled_date, sheduledDate] = useState("");
    const [ordered_date, orderedDate] = useState("");
    const [instructions, setInstructions] = useState("");
    const [note, setNote] = useState("");
    const [statusus, setStatusus] = useState("");
    const [highlightColor, setHighLightColor] = useState('');
    const [products, setProducts] = useState("");
    const [eta, setEta] = useState();
    const [global_currency, setGlobalCurrency] = useState('');
    const [corporate_address, setCorporateAddess] = useState('');
    const [support_email, setSupportEmail] = useState('');
    const [support_phone, setSupportPhone] = useState('');
    const [business_name, setBusinessName] = useState('');
    const [serachedname, serachedName] = useState('');
    const [res_name, setRestaurantName] = useState('');
    const [res_address, setRestaurantAddress] = useState('');
    const [order_type, setOrderType] = useState('');
    const [offer_amount, setOfferAmount] = useState("");
    const handleClick = (event) => {
        setcurrentPage(Number(event.target.id));
    };
    useEffect(async () => {
        let dynamicCSS = await dynamicStylesheet();
        setHighLightColor(dynamicCSS?.highlightColor);
        let projectSettings = await apiRequest([], Url.BASE_URL + '/projectSettings', 'get');
        setCorporateAddess(projectSettings.data?.extracted[0]?.corporate_address);
        setSupportEmail(projectSettings.data?.extracted[0]?.support_email);
        setSupportPhone(projectSettings.data?.extracted[0]?.support_phone);
        setBusinessName(projectSettings.data?.extracted[0]?.business_name);
    }, []);
    const pages = [];
    for (let i = 1; i <= Math.ceil(transaction_data.length / itemsPerPage); i++) {
        pages.push(i);
    }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = transaction_data.slice(indexOfFirstItem, indexOfLastItem);
    const renderPageNumbers = pages.map((number) => {
        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
            return (
                <li
                    key={number}
                    id={number}
                    onClick={handleClick}
                    className={currentPage == number ? "active" : null}
                >
                    {number}
                </li>
            );
        } else {
            return null;
        }
    });

    const handleShow = (item, type) => {
        if (type === "order") {
            if (item?.type_of_order == "pickup") {
                item.delivery_charge = 0;
            }
            setdriverInfo(item?.drivers);
            setOrderDetail(true)
            setShow(false)
            setOrderId(item?.id)
            setOrderType(item?.type_of_order);
            setItemTotal(item?.item_total ? item?.item_total : 0)
            setTaxTotal(item?.tax_total ? item?.tax_total : 0)
            setDeliveryCharge(item?.delivery_charge ? item?.delivery_charge : 0);
            setOfferAmount(item?.offer_amount ? item?.offer_amount : 0);
            setOrderTotal(item?.order_total)
            if (item?.location != undefined) {
                setRestaurantName(item?.location?.name);
            }
            if (item?.location?.address?.address != undefined) {
                setRestaurantAddress(item?.location?.address?.address);
            }
            if (item?.customer != null && item?.customer != undefined) {
                if (item?.customer?.username != null && item?.customer?.username != undefined) {
                    setName(item?.customer?.firstname ? item?.customer?.firstname : item?.customer?.first_name)
                } else {
                    setName(item?.customer?.firstname ? item?.customer?.firstname : item?.customer?.first_name)
                }
                setPhone(item?.customer.mobile)
                setEmail(item?.customer.email)
                setAddressName(item?.customer?.address)
            }
            if (item?.type != null && item?.type != undefined) {
                setType(item?.type[0])
            }
            setOrderNo(item?.orderNo)
            sheduledDate(item?.selected_date)
            orderedDate(item?.ordered_date)
            setInstructions(item?.instruction)
            setNote(item?.note)
            if (item?.status != null && item?.status != undefined) {
                setStatusus(item?.status?.order_status[0])
            }
            if (item?.orderDetails) {
                setProducts(item?.orderDetails)
            }
            if (item?.address != null && item?.address != undefined) {
                setAddressName(item?.address?.text)
            }
        }
        else if (type === "eta") {
            setOrderDetail("")
            setShow(true)
            setOrderId(item?.id)
            setEta(item?.eta)
        }
    };
    const handleOrderDetailClose = async () => {
        setOrderDetail(false)
    }
    const renderData = (data) => {
        return (
            <TransactionList
                values={{
                    data: data,
                    global_date: global_date,
                    handleShow: handleShow,
                    handleDownloadPdf: handleDownloadPdf
                }}
            />
        );
    };
    useEffect(async () => {
        var localSettings = await setLocalizationSettings();
        await setGlobalDateFormat(localSettings?.[0]);
        await setGlobalCurrency(localSettings?.[1]);
        setTimeout(function () {
            getTransactions();
        }, 2000);
    }, [global_date]);
    const getTransactions = async () => {
        let endpoint = Url.TRANSACTIONS + '?filter={"order":"id desc","include":[{"relation":"customer"},{"relation":"order","scope":{"include":[{"relation":"orderDetails","scope":{"include":[{"relation":"product","scope":{"include":"pricings"}},{"relation":"addOns"}]}},{"relation":"status"},{"relation":"tip"},{"relation":"location","scope":{"include":"address"}},{"relation":"drivers"},{"relation":"customer"}]}}]}'
        var get_staff = await apiRequest([], endpoint, 'get')
        console.log(get_staff);
        if (get_staff == null || get_staff == undefined || get_staff.length <= 0 || get_staff.data.length <= 0) {
            show_mesage("No records found")
            setLoading(false);
        }
        else {
            let sp = []
            let status = ''
            get_staff.data.forEach(function (post, i) {
                if (post.order != null && post.order.status != null) {
                    status = post.order.status.order_status[0]
                }
                else {
                    status = ''
                }
                let s = {
                    order_no: post?.order?.orderNo ? post?.order?.orderNo : "",
                    name: post?.customer?.firstname ? post?.customer?.firstname : post?.customer?.first_name,
                    no_of_items: (post.order != null && post.order.orderDetails != undefined) ? post.order.orderDetails.length : "",
                    amount: post.amount,
                    order_type: post?.order?.type_of_order == "delivery" ? "Delivery" : "Pickup",
                    location: post?.order?.location?.name,
                    driver: post?.order?.drivers?.first_name ? post?.order?.drivers?.first_name : post?.order?.drivers?.firstname,
                    transaction_date: moment(post.transaction_date).format(global_date),
                    delivery_time: moment(post.transaction_date).format(global_date),
                    order_status: status,
                }

                sp.push(s)

            })
            setData(sp)
            if (sp[0] != null && sp[0] != undefined) {
                let keys = Object.keys(sp[0])
                let ss = []
                keys.forEach(function (post, i) {
                    let s = {
                        key: post,
                        name: post,
                    }
                    ss.push(s)
                })
                setHeaders(ss)
            }
            if (localStorage.getItem("role") == "locationmanager") {
                var transactions_info = [];
                var assigned_locations = localStorage.getItem("locations").split(',');
                for (var i = 0; i < get_staff.data.length; i++){
                    for (var j = 0; j < assigned_locations.length; j++){
                        if (get_staff.data[i].order.locationId == assigned_locations[j]) {
                            transactions_info.push(get_staff.data[i]);
                        }
                    }
                }
                setTimeout(() => {
                    setTransactions(transactions_info)
                    setTransactionsData(transactions_info)
                    setFilteredTransactions(transactions_info);
                    setLoading(false);
                }, 200);
            } else {
                setTransactions(get_staff.data)
                setTransactionsData(get_staff.data)
                setFilteredTransactions(get_staff.data);
                setLoading(false);
            }
        }
    }
    const handleCallback = async (childData) => {

    }
    const filterDateRange = async (daterange) => {
        let mindate = new Date(daterange[0]);
        let maxdate = new Date(daterange[1]);
        mindate.setHours(0, 0, 0, 0)
        maxdate.setHours(0, 0, 0, 0);
        let ORDERS = [...filtered_transactions]
        ORDERS.forEach(function (p, i) {
            if (p.transaction_date != null && p.transaction_date != undefined) {
                let d = new Date(p.transaction_date);
                d.setHours(0, 0, 0, 0);
                if (d >= mindate) {
                    if (maxdate >= d) {
                        range_data.push(p)
                    }
                }
            }
        })
        setTransactionsData(range_data);
        setTransactions(range_data);
    }
    const handleNextbtn = () => {
        setcurrentPage(currentPage + 1);
        if (currentPage + 1 > maxPageNumberLimit) {
            setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    };
    const handlePrevbtn = () => {
        setcurrentPage(currentPage - 1);
        if ((currentPage - 1) % pageNumberLimit == 0) {
            setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
    };
    let pageIncrementBtn = null;
    if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
    }
    let pageDecrementBtn = null;
    if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
    }
    const handleLoadMore = () => {
        setitemsPerPage(itemsPerPage + 5);
    };
    const handleDownloadPdf = async (item, order) => {
        handleShow(item, order);
        setTimeout(function () {
            if (order == "order") {
                const element = printRef.current;
                const canvas = html2canvas(element)
                    .then((canvas) => {
                        // const imgData = canvas.toDataURL('image/png');
                        // const pdf = new jsPDF('p', 'pt', 'a3', true)
                        // pdf.addImage(imgData, 'JPEG', 0, 0);
                        // pdf.save("order-details.pdf");

                        // another implementation

                        // const doc = new jsPDF({
                        //     orientation: 'p',
                        //     format: 'a3',
                        //     unit: 'pt',
                        // });

                        // // Adding the fonts.
                        // doc.setFont('Inter-Regular', 'normal');

                        // doc.html(element, {
                        //     async callback(doc) {
                        //         await doc.save('document');
                        //     },
                        // });
                        const imgData = canvas.toDataURL('image/png');
                        const pdf = new jsPDF({
                            orientation: 'p',
                            format: 'a3',
                            unit: 'pt',
                        });
                        
                     
                        pdf.html(element, {
                            async callback(doc) {
                                await doc.save('document');
                            },
                        });
                    });
            }
            setOrderDetail(false);
        }, 1000);

    };

    const filterByNameOrId = async (e) => {
        const searchString = e.target.value.toLowerCase()?.trim();
        serachedName(searchString);
        if (searchString.length > 0) {
            const filterTransactions = transactions.filter(item => {
                if (item?.customer?.firstname == undefined) {
                    return (
                        item?.customer?.first_name.toLowerCase().includes(searchString) || item?.order?.orderNo.includes(searchString)
                    )
                } else {
                    return (
                        item?.customer?.firstname.toLowerCase().includes(searchString) || item?.order?.orderNo.includes(searchString)
                    )
                }
            });
            if (filterTransactions) {
                setTransactionsData(filterTransactions);
                setcurrentPage(1);
            } else {
                getTransactions();
            }
        } else {
            getTransactions();
        }
    }

    const filterTransactionsByStatus = (e) => {
        let status = e.target.value;
        if (status == "all") {
            getTransactions();
        } else if (status == "delivery" || status == "pickup") {
            const filterTransactions = transactions.filter(item => {
                if (status != null) {
                    return (
                        item?.order?.type_of_order == status
                    )
                }
            });
            if (filterTransactions) {
                setTransactionsData(filterTransactions);
                setcurrentPage(1);
            }
        }
    }

    return (
        <div>
            <html lang="en">
                <script src="https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.23.0/moment.js"></script>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                <div class="sticky">
                    <Header parentCallback={handleCallback} />
                </div>
                <body>
                    <div class="middle_block">
                        <Sidebar tabvalue='staff' />
                        <div class="main_content">
                            <div class="container-fluid">
                                <div class="manage_staff">
                                    <div class="py-3">
                                        <div class="row">
                                            <div class="col-lg-3 col-md-3 col-sm-4 col-12"><h2 class="head_sty1">Transaction Report</h2></div>
                                            <div class="col-lg-3 col-md-3 col-sm-4 col-12">
                                                <div class="search_box_wrap search_customer">
                                                    <div style={{ "display": "flex" }}>
                                                        <div style={{ "display": "flex" }}>
                                                            <input type="text" onChange={(e) => filterByNameOrId(e)} id="searchbar" id="search" class="form-control" placeholder="Type Name or Order Id.." />
                                                            <i style={{ "background": highlightColor ? highlightColor : "#fff" }} class="fa fa-search fa-border custom_icon"></i>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="col-lg-2 col-md-2 col-sm-4 col-12">
                                                <div class="order_search">
                                                    <select onChange={(e) => filterTransactionsByStatus(e)} class="form-control">
                                                        <option value="all">All</option>
                                                        <option value="delivery">Delivery</option>
                                                        <option value="pickup">Pickup</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="col-lg-2 col-md-2 col-sm-4 col-12">
                                                <div class="s_date_wrap" style={{ "margin-left": "auto" }}>

                                                    <div class="s_date_wrap" style={{ "position": "relative" }}>
                                                        <DatePicker
                                                            onKeyDown={(e) => {
                                                                e.preventDefault();
                                                            }}
                                                            style={{ "width": "initial !important" }}
                                                            className="form-control dateClass"
                                                            selectsRange={true}
                                                            startDate={startDate}
                                                            endDate={endDate}
                                                            onChange={(update) => {
                                                                if (update == 0 || update == '' || update == [] || update == undefined || update == ',') {
                                                                    getTransactions();
                                                                }
                                                                setDateRange(update);
                                                                if (update.length == 2 && update[0] != null && update[1] != null) {
                                                                    filterDateRange(update)
                                                                    return
                                                                }
                                                                else if (update.length !== 2 || update[0] != null || update[1] != null) {
                                                                    setTransactions(transaction_data);
                                                                    setTransactionsData(transaction_data)
                                                                    setcurrentPage(1);
                                                                }
                                                            }}
                                                        />
                                                        <i class="icofont-ui-calendar transactions_calender_icon"></i>
                                                    </div>


                                                </div>

                                            </div>

                                            <div class="col-lg-2 col-md-2 col-sm-6 col-12">
                                                <ExportJsonCsv fileTitle="transactions" className="btn_sty_1 float-right" style={{
                                                    "color": "#fff", backgroundColor: '#319f60', "font-size": "14px", "border-radius": "5px",
                                                    cursor: "pointer", "margin-left": "10px", "padding": "12px 20px", "border": "none", "display": "inline-block", "font-weight": "700", "text-transform": "upper-case", "text-align": "center"
                                                }} headers={headers} items={data}>EXPORT</ExportJsonCsv>
                                            </div>
                                        </div>
                                    </div>
                                    {(loading == false) ? renderData(currentItems) : <img className="loading" src={LoaderGif()}></img>}
                                    <Modal class="modalform" size="lg" class="modal-dialog modal-dialog-centered modal-lg" style={{ 'width': '1000px;', 'padding-top': '150px' }} fullscreen='true' show={order_detail} onHide={handleOrderDetailClose}>
                                        <TransactionInvoice
                                            values={{
                                                printRef: printRef,
                                                orderno: orderno,
                                                order_type: order_type == "delivery" ? "Delivery" : "Pickup",
                                                global_date: global_date,
                                                ordered_date: ordered_date,
                                                name: name,
                                                address_name: address_name,
                                                res_name: res_name,
                                                products: products,
                                                global_currency: global_currency,
                                                item_total: item_total,
                                                tax_total: tax_total,
                                                order_total: order_total,
                                                driverInfo: driverInfo,
                                                delivery_charge: delivery_charge,
                                                offer_amount: offer_amount
                                            }}
                                        />
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </body>
            </html>
            <ul className="pageNumbers">
                <li onClick={handlePrevbtn}
                    className={currentPage == pages[0] ? "disallow" : "allow"}>
                    {"<"}
                </li>
                {pageDecrementBtn}
                {renderPageNumbers}
                {pageIncrementBtn}
                <li onClick={handleNextbtn}
                    className={currentPage == pages[pages.length - 1] ? "disallow" : "allow"}>
                    {">"}
                </li>
            </ul>
        </div>
    )
}
export default Transactions;