export const PromocodesStatusFilter = (filterPromocodes) => {
    return (
        <div class="">
            <select onChange={(e) => filterPromocodes(e.target.value)} id="driver_filter" class="form-control" name="driver_filter">
                <option value="all">All</option>
                <option value="expired">Expired</option>
                <option value="not expired">Not Expired</option>
            </select>
        </div>
    );
}