function LocationManagerList(props) {
    function DisplayLocations(assigned_locations, all_locations) {
        var locations_all = [];
        for (let i = 0; i < all_locations.length; i++){
            if (all_locations[i].label != "All") {
                locations_all.push(all_locations[i].label);
            }
        }
        if (assigned_locations.join('') == locations_all.join('')){
            return "All";
        } else {
            return assigned_locations ? assigned_locations.map(function (item, index) {
                return item;
            }).join(', ') : ""
        }
    }
    return (
        <div>
            {
                props.values.data.length > 0
                    ? props.values.data?.map((item, index) => {
                        return (
                            <div class="m_staff_box">
                                <div class="m_staff_top">
                                    <div class="img_box"><img src={item['profile_image'] ? item['profile_image'] : './img-placeholder.png'} alt="" /></div>
                                    <div class="m_staff_info">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <h2 class="r_name">{item['first_name']}</h2>
                                            </div>
                                            <div class="col-md-6 r_align_right view_id_btn_wrap">
                                                <div href="#" class="panel-heading active" class="view_id_btn" data-toggle="modal" data-target="#idcardModal" onClick={e => props.values.handleShowID(item['ID'])}><a href="#" class="hrefcol">View ID</a></div>
                                            </div>
                                            <div class="col-md-6">
                                                <h4><span>Phone:</span> {item['mobile']}</h4>
                                            </div>
                                            <div class="col-md-6 r_align_right">
                                                <h4><span>Email:</span> {item['email']}</h4>
                                            </div>
                                        </div>
                                        <div class="m_staff_bottom">
                                            <h4><span>Assigned Location : </span>
                                                {DisplayLocations(item.locs,props.values.locations)}
                                            </h4><br></br><br></br>
                                            
                                        </div>
                                        <div style={{"text-align":"right"}} class="btn_group">
                                            <a href="#" class="grey_btn" data-target="#edit_staffModal" onClick={e => props.values.onEdit(item)}>Edit</a>&nbsp;&nbsp;
                                            {
                                                (item.block_status == "unblocked") ? <a id={item.id} href="javascript:void(0);" class="grey_btn" style={{ "width": "106px" }} onClick={e => props.values.changeBlockStatus(item.id, 'blocked')}>Block</a> : <a id={item.id} href="javascript:void(0);" class="grey_btn" onClick={e => props.values.changeBlockStatus(item.id, 'unblocked')}>Unblock</a>
                                            }
                                            &nbsp;&nbsp;
                                            <a href="#" class="grey_btn" onClick={e => props.values.onDelete(item.id)}>Remove</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    : <div align="center" style={{ 'font-size': '16' }} >{props.values.view_show_msg}</div>
            }
        </div>
    );
}
export default LocationManagerList;