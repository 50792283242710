export default function DeleteImg(){
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="11.669" height="15" viewBox="0 0 11.669 15">
            <g id="delete" transform="translate(0)">
                <path id="Path_3641" data-name="Path 3641" d="M126.37.905h2.507v.422h.905V.846A.847.847,0,0,0,128.937,0h-2.626a.847.847,0,0,0-.846.846v.481h.905Zm0,0" transform="translate(-121.789 0)" fill="#555" />
                <path id="Path_3642" data-name="Path 3642" d="M49.389,167.75h-8.5a.4.4,0,0,0-.4.431l.711,8.786a.944.944,0,0,0,.94.868h5.992a.944.944,0,0,0,.94-.868l.711-8.786a.4.4,0,0,0-.4-.431ZM42.947,176.9h-.028a.453.453,0,0,1-.451-.425l-.445-7.213a.453.453,0,0,1,.9-.056l.445,7.213A.453.453,0,0,1,42.947,176.9Zm2.649-.452a.453.453,0,0,1-.905,0v-7.213a.453.453,0,1,1,.905,0Zm2.659-7.187-.425,7.213a.453.453,0,0,1-.451.426h-.027a.453.453,0,0,1-.425-.479l.425-7.213a.453.453,0,0,1,.9.053Zm0,0" transform="translate(-39.304 -162.835)" fill="#555" />
                <path id="Path_3643" data-name="Path 3643" d="M11.65,77.5l-.3-.891a.576.576,0,0,0-.546-.393H.863a.576.576,0,0,0-.546.393l-.3.891a.374.374,0,0,0,.354.492H11.3a.369.369,0,0,0,.2-.057.373.373,0,0,0,.157-.435Zm0,0" transform="translate(0 -73.979)" fill="#555" />
            </g>
        </svg>
    );
}