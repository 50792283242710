import PaginationComponent from "../restaurant_categories";

function ResCategories() {
  return (
    <div className="App">
      <div>
        <PaginationComponent />
      </div>
    </div>
  );
}

export default ResCategories;