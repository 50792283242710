import Moment from "react-moment";
function Transactions(props) {
    return (
        props.values.data.length > 0 ?
            <div class="table-responsive">
                <table class="table transaction_table" >
                    <thead>
                        <tr>
                            <th style={{ "min-width": "80px" }}>Order Id</th>
                            <th style={{ "min-width": "80px" }}>Status</th>
                            <th style={{ "min-width": "80px" }}>Customer Name</th>
                            <th style={{ "min-width": "80px" }}>Items</th>
                            <th style={{ "min-width": "80px" }}>Amount</th>
                            <th style={{ "min-width": "80px" }}>Type</th>
                            <th style={{ "min-width": "80px" }}>{localStorage.getItem("multi_restaurant")=='true' ? 'Restaurant' : 'Location'}</th>
                            <th style={{ "min-width": "80px" }}>Driver</th>
                            <th style={{ "min-width": "80px" }}>Tip</th>
                            <th style={{ "min-width": "80px" }}>Order Time</th>
                            <th style={{ "min-width": "80px" }}>Delivery Time</th>
                            <th style={{ "min-width": "80px" }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.values.data.length > 0
                                ? props.values.data?.map((item, index) => {
                                    let status = '';
                                    let color = '';
                                    status = item?.order?.status?.order_status?.[0];
                                    if (status == "Pending") {
                                        color = "orange";
                                    } else if (status == "Accepted") {
                                        color = "blue";
                                    } else if (status == "Delivered") {
                                        color = "green";
                                    } else if (status == "Cancelled") {
                                        color = "red";
                                    } else if (status == "On The Way") {
                                        color = "violet";
                                    } else if (status == "Prepared") {
                                        color = "black";
                                    }
                                    else if (status == "Picked Up") {
                                        color = "black";
                                    }
                                    else if (status == "Preparing") {
                                        color = "black";
                                    }
                                    return (
                                        <tr>
                                            <td>{(item.order != null && item.order.orderNo != undefined) ? item.order.orderNo : ""}</td>
                                            <td><button class="form-control btn" style={{ "background-color": color, "color": "white" }}>{status}</button></td>
                                            <td>{item?.customer?.firstname ? item?.customer?.firstname : item?.customer?.first_name}</td>
                                            <td>{(item.order != null && item.order.orderDetails != undefined) ? item.order.orderDetails.length : ""}</td>
                                            <td>{parseFloat(item?.order?.order_total).toFixed(2)}</td>
                                            <td>{item?.order?.type_of_order == "delivery" ? "Delivery" : "Pickup"}</td>
                                            <td>{item?.order?.location?.name}</td>
                                            <td>{item?.order?.drivers?.first_name ? item?.order?.drivers?.first_name : item?.order?.drivers?.firstname}</td>
                                            <td>{(item?.order?.tip_amount == 0 || item?.order?.tip_amount == undefined) ? 'No Tip Received' : item?.order?.tip_amount}</td>
                                            <td><Moment format={props.values.global_date ? props.values.global_date : ' DD MMM  YYYY-h:mm A'}>{item.transaction_date}</Moment></td>
                                            <td>{item?.order?.delivered_on != undefined ? <Moment format={props.values.global_date ? props.values.global_date : ' DD MMM  YYYY-h:mm A'}>{item?.order?.delivered_on}</Moment> : ""}</td>
                                            <td>
                                                {color == "green" ? <div><a href="#" onClick={() => props.values.handleShow(item?.order, 'order')}><i className="fa fa-eye"></i></a> &nbsp; &nbsp;<a href="#" onClick={() => props.values.handleDownloadPdf(item?.order, 'order')}><i className="fa fa-download"></i></a></div> : ""}

                                            </td>

                                        </tr>
                                    )
                                })
                                : <div align="center" style={{ 'font-size': '16' }} >no records found</div>
                        }
                    </tbody>
                </table>
            </div> : <center><b>No records found!!</b></center>
    );
}
export default Transactions;