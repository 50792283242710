import Moment from "react-moment";
function PromoCodesList(props) {
    return (
        props.values.data.length > 0 ?
            <div>
                {
                    props.values.data.length > 0
                        ? props.values.data?.map((item, index) => {
                            return (
                                <div class="m_staff_box">
                                    <div class="m_staff_top">
                                        <div class="img_box"><img src={item['image']} alt="" /></div>
                                        <div class="m_staff_info">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <h2 class="r_name">{item.name}</h2>
                                                </div>
                                                {
                                                    (item?.end_time >= new Date().toISOString() || item.validaty == "lifetime" || item.validaty=="maximum_validity_count") ?
                                                        <div class="col-md-6 r_align_right view_id_btn_wrap">
                                                            <label style={{ "margin-left": "189px" }} class="switch">
                                                                <input type="checkbox" disabled={false}
                                                                    checked={props.values.get_data[index].enable} value={item.enable} onChange={e => props.values.handleCheckbox(item, index)} />
                                                                <span class="slider round"></span> </label>
                                                        </div>
                                                        :
                                                        <div class="col-md-6 r_align_right view_id_btn_wrap">
                                                            <p style={{ "color": "red" }}>Expired</p>
                                                        </div>
                                                }

                                                <div class="col-md-6">
                                                    <p><strong>Valid On : </strong>
                                                        {
                                                            (item.type != null && item.type != undefined && item.type != "") ?
                                                                item.type : null
                                                        }
                                                    </p>
                                                </div>
                                                <div class="col-md-6 r_align_right">
                                                    <h4><span>Value:</span> {item.amount}  {(item.value == "Percent") ? "%" : ""}</h4>
                                                </div>
                                            </div>
                                            <div class="m_banners_bottom">
                                                <h4><span>Validity : </span>
                                                    {item.validaty == "lifetime" ? "Life Time" : ""}
                                                    {item.validaty == "maximum_validity_count" ? `${item.validity_count}` : ""}
                                                    {item.validaty == "specific_date" ? <Moment format={props.values.global_date ? props.values.global_date : " DD MMM  YYYY"}>{item.end_time}</Moment> : ""}
                                                    {item.validaty == "date_range" ? <><Moment format={props.values.global_date ? props.values.global_date : " DD MMM  YYYY"}>{item.start_time}</Moment> - <Moment format={props.values.global_date ? props.values.global_date : " DD MMM  YYYY"}>{item.end_time}</Moment></> : ""}
                                                </h4>
                                                <h4><span>Validity Type : </span>
                                                    {item.validaty}
                                                </h4>



                                                <div class="btn_group">
                                                    <i>Redeem Count : <b>{item.redeem_count ? item.redeem_count : 0}</b></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <a href="#" class="grey_btn" onClick={e => props.values.onEdit(item)}>Edit</a>

                                                    <a href="#" class="grey_btn" data-toggle="modal" onClick={e => props.values.onDelete(item.id)} data-target="#remove_promoModal">Remove</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        : <div align="center" style={{ 'font-size': '16' }} >{props.values.view_show_msg}</div>
                }
            </div> : <center><b>No records found!!</b></center>
    );
}
export default PromoCodesList;