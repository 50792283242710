import Moment from "react-moment";
function Messages(props) {
    return (
        props.values.data.length > 0 ?
            <div class="table-responsive">
                <table class="table transaction_table" >
                    <thead>
                        <tr>
                            <th style={{ "min-width": "80px" }}>S.No</th>
                            <th style={{ "min-width": "80px" }}>Name</th>
                            <th style={{ "min-width": "80px" }}>Email</th>
                            <th style={{ "min-width": "80px" }}>Subject</th>
                            <th style={{ "min-width": "80px" }}>Message</th>
                            <th style={{ "min-width": "80px" }}>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.values.data.length > 0
                                ? props.values.data?.map((item, index) => {
                                    return (
                                        <tr>
                                            <td>{++index}</td>
                                            <td>{item?.name}</td>
                                            <td>{item?.email}</td>
                                            <td>{item?.subject}</td>
                                            <td>{item?.message}</td>
                                            {item?.date_of_contact == undefined ? <td></td> : <td><Moment format={props.values.global_date ? props.values.global_date : " DD MMM  YYYY-h:mm A"}>{item?.date_of_contact}</Moment></td>}
                                            
                                        </tr>
                                    )
                                })
                                : <div align="center" style={{ 'font-size': '16' }} >no records found</div>
                        }
                    </tbody>
                </table>
            </div> : <center><b>No records found!!</b></center>
    );
}
export default Messages;