import { useState, useEffect } from "react";
import Header from "./header"
import Sidebar from "./menu"
import * as URL from '../../src/services/ServiceConstant';
import { Modal } from 'react-bootstrap';
import apiRequest from "../services/ServiceRequest";
import PlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByPlaceId } from "react-places-autocomplete";
import "../assets/css/pagination.css";
import { validateName, validateZipCode } from '../helpers/validation';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { filterLocations, LoaderGif, sortLocations, dynamicStylesheet } from "../utils/common.js"
import LocationList from "./list/location";
import { count } from "rsuite/esm/utils/ReactChildren";
const Location = (props) => {
    toast.configure()
    var locations_image = []
    var location_image = []
    var method;
    const [loc_images, location_images] = useState();
    const [labels, labelText] = useState("");
    const [get_data, setLocation] = useState("");
    const [show, setShow] = useState(false);
    const [highlightColor, setHighLightColor] = useState('');
    const [images, setImage] = useState("");
    const [name, setName] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [lat, setLat] = useState("");
    const [lng, setLng] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [locid, setlocid] = useState("");
    const [adid, setaddid] = useState("");
    const [edits, setEdit] = useState(false);
    const [view_show_msg, show_mesage] = useState("");
    const [address, setAddress] = useState("");
    const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
    const [loading, setLoading] = useState(true);
    const handleSelect = async value => {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        var address_components = value.split(',');
        var country = address_components[address_components.length - 1];
        var state = address_components[address_components.length - 2];
        var city = address_components[address_components.length - 3];
        setCountry(country);
        setState(state);
        setCity(city);
        setAddress(value);
        setCoordinates(latLng);
        setLat(coordinates.lat);
        setLng(coordinates.lng);
        const [place] = await geocodeByPlaceId(results[0].place_id);
        const { long_name: postcode = '' } =
            place.address_components.find(c => c.types.includes('postal_code')) || {};
        setZipcode(postcode);
    };
    const [radius, setRadius] = useState("");
    const handleClose = () => {
        location_name_error("")
        address_error("")
        country_error("")
        state_error("")
        city_error("")
        zipcode_error("")
        location_address_type_error("")
        location_error("")
        setName("")
        setAddress("")
        setCountry("")
        setState("")
        setCity("")
        setZipcode("")
        setRadius("")
        image_error("")
        setShow(false);
    }
    const [view_location_name_error, location_name_error] = useState("");
    const [view_address_error, address_error] = useState("");
    const [view_country_error, country_error] = useState("");
    const [view_state_error, state_error] = useState("");
    const [view_city_error, city_error] = useState("");
    const [view_zipcode_error, zipcode_error] = useState("");
    const [countryCode, setcountryCode] = useState("");
    const [view_location_address_type_error, location_address_type_error] = useState("");
    const [view_location_error, location_error] = useState("");
    const [enabless, setEnables] = useState(false);
    const [viewLocationNameError, setLocationNameError] = useState(false);
    const [viewAddressError, setAddressError] = useState(false);
    const [viewCountryError, setCountryError] = useState(false);
    const [viewStateError, setStateError] = useState(false);
    const [viewCityError, setCityError] = useState(false);
    const [viewZipcodeError, setZipcodeError] = useState(false);
    const notify = (message) => {
        toast.success(message)
    }
    const errornotify = (message) => {
        toast.error(message)
    }
    const handleShow = () => {
        setImage("")
        setShow(true)
        var label = '';
        label = localStorage.getItem("multi_restaurant") == 'true' ? 'Add Restaurant' : 'Add Location';
        labelText(label)
        setEdit(false)
    };
    const onEdit = (edit) => {
        setEdit(true)
        var label = '';
        label = localStorage.getItem("multi_restaurant") == 'true' ? 'Edit Restaurant' : 'Edit Location';
        labelText(label)
        setShow(true)
        if (edit.name != "") {
            setName(edit.name)
        }
        if (edit.images != null && edit.images != undefined) {
            setImage(edit.images)
        }
        if (edit.address.address != null && edit.address.address != undefined && edit.address.address.length > 0) {
            setAddress(edit.address.address)
        }
        if (edit.address.geolocation.lat != "") {
            setLat(edit.address.geolocation.lat)
        }
        if (edit.address.geolocation.lng != "") {
            setLng(edit.address.geolocation.lng)
        }
        setCoordinates({ lat: edit.address.geolocation.lat, lng: edit.address.geolocation.lng })
        if (edit.address.state != "") {
            setState(edit.address.state)
        }
        if (edit.address.city != "") {
            setCity(edit.address.city)
        }
        if (edit.address.country != "") {
            setCountry(edit.address.country)
        }
        if (edit.address.id != "") {
            setaddid(edit.address.id)
        }
        if (edit.address.zipcode != "") {
            setZipcode(edit.address.zipcode)
        }
        if (edit.id != "") {
            setlocid(edit.id)
        }
    }
    const getaddedaddressData = async () => {
        let endpoint = URL.BASE_URL + '/location-and-orders';
        var getaddedaddress = await apiRequest([], endpoint, 'get')
        if (getaddedaddress == null || getaddedaddress == undefined || getaddedaddress.data === '{}' || Object.entries(getaddedaddress.data).length === 0 || getaddedaddress === null || getaddedaddress === undefined || getaddedaddress.length <= 0 || getaddedaddress.data.length <= 0) {
            show_mesage("No records found")
            setLoading(false);
        }
        else {
            if (localStorage.getItem("role") == "locationmanager") {
                var locations_data = [];
                var assigned_locations = localStorage.getItem("locations").split(',');
                for (var i = 0; i < getaddedaddress.data.length; i++){
                    for (var j = 0; j < assigned_locations.length; j++){
                        if (assigned_locations[j] == getaddedaddress.data[i].id) {
                            locations_data.push(getaddedaddress.data[i]);
                        }
                    }
                }
                setTimeout(async() => {
                    var locationData = await sortLocations(locations_data);
                    setLocation(locationData)
                    setLoading(false);
                }, 200);
            } else {
                var locationData = await sortLocations(getaddedaddress.data);
                setLocation(locationData)
                setLoading(false);
            }
        }
    }
    const [currentPage, setcurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(10);
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
    const handleClick = (event) => {
        setcurrentPage(Number(event.target.id));
    };
    const pages = [];
    for (let i = 1; i <= Math.ceil(get_data.length / itemsPerPage); i++) {
        pages.push(i);
    }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = get_data.slice(indexOfFirstItem, indexOfLastItem);
    const renderPageNumbers = pages.map((number) => {
        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
            return (
                <li
                    key={number}
                    id={number}
                    onClick={handleClick}
                    className={currentPage == number ? "active" : null}
                >
                    {number}
                </li>
            );
        } else {
            return null;
        }
    });
    useEffect(async () => {
        let dynamicCSS = await dynamicStylesheet();
        setHighLightColor(dynamicCSS?.highlightColor);
        getaddedaddressData();
        let countryConfigured = await apiRequest({}, URL.BASE_URL + '/getCountryConfigured', 'get');
        setcountryCode(countryConfigured?.data?.countryConfigured);
        let endpoint = URL.BASE_URL + '/location-and-orders';
        var get_loc = await apiRequest([], endpoint, 'get')
        if (get_loc == null || get_loc == undefined || get_loc.data === '{}' || Object.entries(get_loc.data).length === 0 || get_loc === null || get_loc === undefined || get_loc.length <= 0 || get_loc.data.length <= 0) {
            show_mesage("No records found")
            setLoading(false);
        }
        else {
            if (localStorage.getItem("role") == "locationmanager") {
                var locations_data = [];
                var assigned_locations = localStorage.getItem("locations").split(',');
                for (var i = 0; i < get_loc.data.length; i++) {
                    for (var j = 0; j < assigned_locations.length; j++) {
                        if (assigned_locations[j] == get_loc.data[i].id) {
                            locations_data.push(get_loc.data[i]);
                        }
                    }
                }
                setTimeout(async () => {
                    var locationData = await sortLocations(locations_data);
                    setLocation(locationData)
                    setLoading(false);
                }, 200);
            } else {
                var locationData = await sortLocations(get_loc.data);
                setLocation(locationData)
                setLoading(false);
            }
        }
    }, []);
    const handleCheckbox = async (item, index) => {
        let temp = [...get_data]
        if (item.enable === false || item.enable === null || item.enable === undefined || item.enable == '') {
            let ss = true
            item.enable = ss
            item.enable = ss
            let add_response = await apiRequest(item, URL.ADDLOCATION, 'patch')
            if (add_response.error) {
                errornotify("'Failed', response.error.message");
                return;
            } else {
                setLocation(temp)
                getaddedaddressData();
            }
        }
        else if (item.enable === true || item.enable === 'true') {
            let st = false
            item.enable = st
            item.enable = st
            let add_response = await apiRequest(item, URL.ADDLOCATION, 'patch')
            if (add_response.error) {
                errornotify("'Failed', response.error.message");
                return;
            } else {
                setEnables(true)
                setLocation(temp)
                getaddedaddressData();
            }
        }
    }
    const renderData = (data) => {
        return (
            data.length > 0 ?
                <LocationList
                    values={{
                        data: data,
                        get_data: get_data,
                        handleCheckbox: handleCheckbox,
                        onEdit: onEdit,
                        onDelete: onDelete,
                        view_show_msg: view_show_msg
                    }}
                /> : <center><b>No records found</b></center>
        );
    };
    const onSubmit = async () => {
        if (name == '' || address == '' || country == '' || state == '' || city == '' || zipcode == '') {
            setShow(true);
            setName(name)
            const _ValidateFirstName = validateName(name, 'location name');
            if (_ValidateFirstName['status']) {
                location_name_error('')
                setLocationNameError(true)
            }
            else {
                location_name_error(_ValidateFirstName['error'])
                setLocationNameError(false)
            }
            setAddress(address)
            if (address !== '') {
                address_error('')
                setAddressError(true)
            }
            else if (address === '' || address === undefined || address === null) {
                address_error('Please enter  location Address')
                setAddressError(false)
            }
            setCountry(country)
            const _ValidateMobileNo = validateName(country, 'country');
            if (_ValidateMobileNo['status']) {
                country_error('')
                setCountryError(true)
            }
            else {
                country_error(_ValidateMobileNo['error'])
                setCountryError(false)
            }
            setState(state)
            const _ValidateEmail = validateName(state, 'state');
            if (_ValidateEmail['status']) {
                state_error('')
                setStateError(true)
            }
            else {
                state_error(_ValidateEmail['error'])
                setStateError(false)
            }
            setCity(city)
            const _ValidateAssignedLocation = validateName(city, 'city');
            if (_ValidateAssignedLocation['status']) {
                city_error('')
                setCityError(true)
            }
            else {
                city_error(_ValidateAssignedLocation['error'])
                setCityError(false)
            }
            setZipcode(zipcode)
            const _ValidateAssignedRole = validateZipCode(zipcode);
            if (_ValidateAssignedRole['status']) {
                zipcode_error('')
                setZipcodeError(true)
            }
            else {
                zipcode_error(_ValidateAssignedRole['error'])
                setZipcodeError(false)
            }
        }
        else {
            var country_check = /\d/.test(country);
            if (country_check == true) {
                alert("Country is invalid");
                return false;
            }
            var state_check = /\d/.test(state);
            if (state_check == true) {
                alert("State value is invalid!!");
                return false;
            }
            var zipcode_check = /^\d+$/.test(zipcode);
            if (zipcode_check == false) {
                alert("Zipcode value is invalid!!");
                return false;
            }
            setShow(false);
            let formData = new FormData();
            var types = []
            types.push("non");
            var address_data = {
                "address": address,
                "state": state,
                "city": city,
                "country": country,
                "zipcode": zipcode,
                "type": types,
                "radius": "non",
                "delivery_options": [
                    "non"
                ],
                "additional_instructions": [
                    "non"
                ],
                "geolocation": { lat: lat, lng: lng }
            }
            var loc_data = {
                "images": [],
                "name": name,
                "type": "non",
                "enable": true
            }
            try {
                if (images.images != null && images.images != undefined) {
                    formData.append('images', images.images);
                    let image_response = await apiRequest(formData, URL.UPLOAD_IMAGE, 'post')
                    if (image_response.error) {
                        errornotify("'Failed', response.error.message");
                        return;
                    }
                    loc_data.images.push(URL.image_url + image_response.data.images)
                }
                else {
                    if (images == null || images == undefined || images.length <= 0) {
                        loc_data.images = ''
                        image_error('Please select image ')
                        setImageError(false)
                    }
                    else { loc_data.images = images }
                }
                if (edits === false) {
                    method = 'post'
                    let add_response = await apiRequest(address_data, URL.ADDLOCATIONADDRESS, method)
                    if (add_response.error) {
                        errornotify("'Failed', response.error.message");
                        return;
                    } else {
                        var locationaddressId = add_response.data.id
                        loc_data.locationaddressId = locationaddressId
                        loc_data.id_deleted = false;
                        let loc_response = await apiRequest(loc_data, URL.ADDLOCATION, method)
                        if (loc_response.error) {
                            errornotify("'Failed', response.error.message");
                            return;
                        } else {
                            notify("Added successfully")
                            setName("");
                            setImage("");
                            setAddress("");
                            setLat("");
                            setLng("");
                            setCity("");

                            setState("");
                            setCountry("");
                            setlocid("");
                            setaddid("");
                            setZipcode("")
                            window.location.reload();
                        }
                    }
                }
                else if (edits === true) {
                    method = 'patch'
                    address_data.id = adid
                    loc_data.id = locid
                    let add_response = await apiRequest(address_data, URL.ADDLOCATIONADDRESS, method)
                    if (add_response.error) {
                        errornotify("'Failed', response.error.message");
                        return;
                    } else {
                        var locationaddressId = add_response.data.id
                        loc_data.locationaddressId = locationaddressId
                        let loc_response = await apiRequest(loc_data, URL.ADDLOCATION, method)
                        if (loc_response.error) {
                            errornotify("'Failed', response.error.message");
                            return;
                        } else {
                            notify("Updated successfully")
                            setName("");
                            setImage("");
                            setAddress("");
                            setLat("");
                            setLng("");
                            setCity("");
                            setState("");
                            setCountry("");
                            setlocid("");
                            setaddid("");
                            setZipcode("")
                            setTimeout(function () {
                                window.location.reload();
                            }, 2000);
                            var showdata = await apiRequest([], URL.BASE_URL + '/location-and-orders', 'get')
                            var locationData = await sortLocations(showdata.data.models);
                            setLocation(locationData)
                            setLoading(false);
                        }
                    }
                }
            }
            catch (err) {
                // errornotify('Failed');
                return;
            }
        }
    }
    const _onChangeText = (type, text) => {
        if (type === 'location_name') {
            if (text !== '') {
                setName(text)
                const _ValidateFirstName = '';
                if (_ValidateFirstName['status']) {
                    location_name_error('')
                    setLocationNameError(true)
                }
                else {
                    location_name_error(_ValidateFirstName['error'])
                    setLocationNameError(false)
                }
            }
            else if (text === '' || text === undefined || text === null) {
                setName(text)
                location_name_error('Please enter  Location Name')
                setLocationNameError(false)
            }
        }
        if (type === 'location_address') {
            if (text !== '') {
                setAddress(text)
                address_error('')
                setAddressError(true)
            }
            else if (text === '' || text === undefined || text === null) {
                setAddress(text)
                address_error('Please enter  location Address')
                setAddressError(false)
            }
        }
        else if (type === 'location_country') {
            if (text !== '') {
                setCountry(text)
                country_error('')
                setCountryError(true)
            }
            else
                country_error('Please enter Country Name.')
            setCountryError(false)
            setCountry(text)
        }
        else if (type === 'location_state') {
            if (text !== '') {
                setState(text)
                state_error('')
                setStateError(true)
            }
            else
                state_error('Please enter State.')
            setStateError(false)
            setState(text)
        }
        else if (type === 'location_city') {
            if (text !== '') {
                setCity(text)
                city_error('')
                setCityError(true)
            }
            else
                city_error('Please enter City.')
            setCityError(false)
            setCity(text)
        }
        else if (type === 'location_zipcode') {
            if (text !== '') {
                setZipcode(text)
                const _ValidateAssignedLocation = validateZipCode(text);
                if (_ValidateAssignedLocation['status']) {
                    zipcode_error('')
                    setZipcodeError(true)
                }
                else {
                    zipcode_error(_ValidateAssignedLocation['error'])
                    setZipcodeError(false)
                }
            }
            else
                zipcode_error('Please enter zipcode .')
            setZipcodeError(false)
            setZipcode(text)
        }
    }
    const [viewImageError, setImageError] = useState(false);
    const [view_image_error, image_error] = useState("");
    const _onChangeImage = async (images) => {
        if (images == null || images == undefined || images == [] || images == '' || images == {}) {
            image_error('Please select image ')
            setImageError(false)
        }
        if (images.type != "image/jpeg" && images.type != "image/jpg" && images.type != "image/png") {
            image_error("Please upload the following file types: jpeg, and png.");
            return false;
        }
        var _size = images.size;
        if (_size > 1000000) {
            image_error("The size limit for images is 1MB. Reduce the file size and try again.");
            return false;
        }
        let formData = new FormData();
        formData.append('images', images);
        let image_response = await apiRequest(formData, URL.UPLOAD_IMAGE, 'post')
        if (image_response.error) {
            errornotify("'Failed', response.error.message");
            return;
        }
        setImage(URL.image_url + image_response.data.images)
        image_error('');
        location_image.push(URL.image_url + image_response.data.images)
        location_images(location_image)
    }
    const handleCallback = async (childData) => {

    }
    const onDelete = async (id) => {
        if (window.confirm('Are you sure?')) {
            let endpoint = URL.ADDLOCATION
            var delete_addlocation = await apiRequest({
                id: id,
                id_deleted: true
            }, endpoint, 'patch')
            if (delete_addlocation == null || delete_addlocation == undefined || delete_addlocation.data.length <= 0) {
                errornotify("'Failed'");
            }
            else {
                notify("Deleted successfully")
                getaddedaddressData()
            }
        } else {
            return false;
        }
    }
    const SearchData = (value) => {
        let data = get_data;
        const searchString = value.toLowerCase();
        if (searchString.length > 0) {
            const filteredResults = data.filter(item => {
                return (
                    item.name.toLowerCase().includes(searchString)
                )
            });
            console.log(filteredResults);

            if (filteredResults) {
                setLocation(filteredResults);
                setLoading(false);
                setcurrentPage(1);
            } else {
                getaddedaddressData();
            }
        } else {
            getaddedaddressData();
        }
    }
    const handleNextbtn = () => {
        setcurrentPage(currentPage + 1);
        if (currentPage + 1 > maxPageNumberLimit) {
            setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    };
    const handlePrevbtn = () => {
        setcurrentPage(currentPage - 1);
        if ((currentPage - 1) % pageNumberLimit == 0) {
            setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
    };
    let pageIncrementBtn = null;
    if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
    }
    let pageDecrementBtn = null;
    if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
    }
    const handleLoadMore = () => {
        setitemsPerPage(itemsPerPage + 5);
    };
    const searchOptions = {
        types: ['address'],
        //   componentRestrictions: { country: [countryCode ? countryCode : "IN"] }
    }
    return (
        <div>
            <html lang="en">
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                <div class="sticky">
                    <Header parentCallback={handleCallback} />
                </div>
                <body>
                    <div class="middle_block">
                        <Sidebar />
                        <div class="main_content">
                            <div class="container-fluid">
                                <div class="manage_staff">
                                    <div class="sec_title">
                                        <h2 class="head_sty1">{
                                            localStorage.getItem("multi_restaurant") == "true" ? "Restaurants" : "Locations"
                                        }</h2>
                                        <div class="search_box_wrap search_customer">
                                            <div style={{ "display": "flex" }}>
                                                <div style={{ "display": "flex" }}>
                                                    <input autocomplete="off" type="text" onChange={(e) => SearchData(e.target.value)} id="searchbar" id="search" class="form-control" placeholder="Type to search..." />
                                                    <i style={{ "background": highlightColor ? highlightColor : "#fff" }} class="fa fa-search fa-border custom_icon"></i>
                                                </div>
                                                <button class="btn_sty_1" data-toggle="modal" data-target="#add_locationModal" onClick={handleShow}>Add</button>
                                            </div>
                                        </div>
                                    </div>
                                    {(loading == false) ? renderData(currentItems) : <img className="loading" src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif"></img>}
                                </div>
                            </div>
                            <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                            <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                            <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                            <script src="js/jquery.mCustomScrollbar.concat.min.js"></script>
                            <Modal class="modalform" className="globle-popup-width" size="lg" class="modal-dialog modal-dialog-centered modal-lg" style={{ 'width': '1000px;', 'padding-top': '110px' }} fullscreen='true' show={show} onHide={handleClose}>
                                <div class="modal-content">
                                    <Modal.Header closeButton>
                                        <Modal.Title>{labels}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div class="modal-body">
                                            <section class="staff_profile_sec">
                                                <div class="row">
                                                    <div class="col-md-4"><div class="staff_profile_pic_box">
                                                        <div class="staff_profile_img">
                                                            {
                                                                (images == '') ?
                                                                    <img src={'./img-placeholder.png'} alt="" /> : <img src={images} alt="" />
                                                            }
                                                        </div>
                                                        <label class="custom-file-upload">
                                                            <input type="file" value={locations_image} onChange={e => _onChangeImage(e.target.files[0])} />
                                                            Upload Image
                                                        </label>
                                                        {
                                                            (viewImageError) ?
                                                                <small></small> : <><small style={{ color: 'red' }}>{view_image_error}</small></>
                                                        }
                                                    </div></div>
                                                    <div class="col-md-8"><div class="staff_profile_form">
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label>{
                                                                        localStorage.getItem("multi_restaurant") == 'true' ? 'Restaurant' : 'Location'
                                                                    } Name</label>
                                                                    <input type="text" class="form-control" value={name} onChange={e => _onChangeText('location_name', e.target.value)} />
                                                                    {
                                                                        (viewLocationNameError) ?
                                                                            <small></small> : <><small style={{ color: 'red' }}>{view_location_name_error}</small></>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group" >
                                                                    <label>Address</label>
                                                                    <PlacesAutocomplete
                                                                        searchOptions={
                                                                            searchOptions
                                                                        }
                                                                        value={address}
                                                                        onChange={setAddress}
                                                                        onSelect={handleSelect}
                                                                    >
                                                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                                            <div>
                                                                                {setLat(coordinates.lat)}
                                                                                {setLng(coordinates.lng)}
                                                                                <input className="form-control" {...getInputProps({ placeholder: "Type address" })} />

                                                                                <div className="autocomplete-dropdown-container">
                                                                                    {loading && <div>Loading...</div>}
                                                                                    {suggestions.map(suggestion => {
                                                                                        const className = suggestion.active
                                                                                            ? 'suggestion-item--active shadow bg-white rounded'
                                                                                            : 'suggestion-item shadow bg-white rounded';
                                                                                        const style = suggestion.active
                                                                                            ? { backgroundColor: '#e9ecef', cursor: 'pointer', width: '238px' }
                                                                                            : { padding: '3px', backgroundColor: '#e9ecef', "box-shadow": "1px 1px #e9ecef", cursor: 'pointer', "margin": "0px", "border": "1px solid #e9ecef", "border-radius": "1px", width: '238px' }
                                                                                        return (
                                                                                            <div
                                                                                                {...getSuggestionItemProps(suggestion, {
                                                                                                    className,
                                                                                                    style,
                                                                                                })}
                                                                                            >
                                                                                                <span><svg xmlns="http://www.w3.org/2000/svg" width="22" height="12" viewBox="0 0 14.992 19.989">
                                                                                                    <g id="placeholder" transform="translate(-64)">
                                                                                                        <g id="Group_6964" data-name="Group 6964" transform="translate(64)">
                                                                                                            <g id="Group_6963" data-name="Group 6963">
                                                                                                                <path id="Path_3633" data-name="Path 3633" d="M71.5,0A7.5,7.5,0,0,0,64,7.5c0,5.2,6.766,12.022,7.054,12.311a.627.627,0,0,0,.885,0c.287-.289,7.054-7.115,7.054-12.311A7.5,7.5,0,0,0,71.5,0Zm0,18.461c-1.492-1.6-6.247-7-6.247-10.965a6.247,6.247,0,0,1,12.493,0C77.743,11.461,72.988,16.865,71.5,18.461Z" transform="translate(-64)" />
                                                                                                            </g>
                                                                                                        </g>
                                                                                                        <g id="Group_6966" data-name="Group 6966" transform="translate(67.748 3.748)">
                                                                                                            <g id="Group_6965" data-name="Group 6965">
                                                                                                                <path id="Path_3634" data-name="Path 3634" d="M163.748,96a3.748,3.748,0,1,0,3.748,3.748A3.753,3.753,0,0,0,163.748,96Zm0,6.247a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,163.748,102.247Z" transform="translate(-160 -96)" />
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </svg>{suggestion.description}</span>
                                                                                            </div>
                                                                                        );
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </PlacesAutocomplete>
                                                                    {
                                                                        (viewAddressError) ?
                                                                            <small></small> : <><small style={{ color: 'red' }}>{view_address_error}</small></>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label>Country</label>
                                                                    <input type="text" class="form-control" value={country} onChange={e => _onChangeText('location_country', e.target.value)} />
                                                                    {
                                                                        (viewCountryError) ?
                                                                            <small></small> : <><small style={{ color: 'red' }}>{view_country_error}</small></>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label>State</label>
                                                                    <input type="text" class="form-control" value={state} onChange={e => _onChangeText('location_state', e.target.value)} />
                                                                    {
                                                                        (viewStateError) ?
                                                                            <small></small> : <><small style={{ color: 'red' }}>{view_state_error}</small></>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label>City</label>

                                                                    <input type="text" class="form-control" value={city} onChange={e => _onChangeText('location_city', e.target.value)} />
                                                                    {
                                                                        (viewCityError) ?
                                                                            <small></small> : <><small style={{ color: 'red' }}>{view_city_error}</small></>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label>Zip Code</label>
                                                                    <input type="text" class="form-control" value={zipcode} onChange={e => _onChangeText('location_zipcode', e.target.value)} />
                                                                    {
                                                                        (viewZipcodeError) ?
                                                                            <small></small> : <><small style={{ color: 'red' }}>{view_zipcode_error}</small></>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div></div></div>
                                            </section>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer >
                                        <div class="col-md-12">
                                            <div class="form-group mb-0 staff_profile_btn_group">
                                                <button type="submit" class="btn_sty_1" style={{ "width": '110px' }} onClick={() => onSubmit()}>Submit</button>
                                            </div>
                                        </div>
                                    </Modal.Footer>
                                </div>
                            </Modal>
                        </div>
                    </div>
                </body>
            </html>
            <ul className="pageNumbers">
                <li onClick={handlePrevbtn}
                    className={currentPage == pages[0] ? "disallow" : "allow"}>
                    {"<"}
                </li>
                {pageDecrementBtn}
                {renderPageNumbers}
                {pageIncrementBtn}
                <li onClick={handleNextbtn}
                    className={currentPage == pages[pages.length - 1] ? "disallow" : "allow"}>
                    {">"}
                </li>
            </ul>
        </div>
    )
}
export default Location;