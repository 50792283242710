import { Modal } from 'react-bootstrap';
import Moment from "react-moment";
function TransactionInvoice(props) {
    return (
        <div class="modal-content">
            <Modal.Header closeButton>
                <Modal.Title>Invoice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                    <link
                        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css"
                        rel="stylesheet"
                    />
                    <div ref={props.values.printRef} id="invoice" className="container">
                        <div className="col-md-12">
                            <div className="invoice">
                                <div className="invoice-company text-inverse f-w-600">
                                    <span className="pull-right hidden-print">

                                    </span>
                                    Invoice Details
                                </div>
                                <table style={{ "width": "100%", "text-align": "left" }}>

                                    <tr>
                                        <td style={{ "width": "200px" }}><b>Order ID:</b></td>
                                        <td>{props.values.orderno}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ "width": "200px" }}><b>Order Type:</b></td>
                                        <td>{props.values.order_type}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ "width": "200px" }}><b>Order Time:</b></td>
                                        <td><Moment format={props.values.global_date ? props.values.global_date : " DD MMM  YYYY-h:mm A"}>{props.values.ordered_date}</Moment></td>
                                    </tr>
                                    <tr>
                                        <td style={{ "width": "200px" }}><b>Customer:</b></td>
                                        <td>{props.values.name}</td>
                                    </tr>
                                    {
                                        props.values.order_type == "delivery" ?
                                            <tr>
                                                <td style={{ "width": "200px" }}><b>Delivery Address:</b></td>
                                                <td>{props.values.address_name}</td>
                                            </tr> : ""
                                    }
                                    <tr>
                                        <td style={{ "width": "200px" }}><b>Location:</b></td>
                                        <td>{props.values.res_name}</td>
                                    </tr>
                                </table><br></br>
                                <div className="invoice-content">
                                    <div className="table-responsive">
                                        <table className="table table-invoice">
                                            <thead>
                                                <tr style={{ "background": "#eaeaea" }}>

                                                    <th className="text-center" width="10%">Name</th>
                                                    <th className="text-center" width="10%">
                                                        Price
                                                    </th>
                                                    <th className="text-center" width="10%">
                                                        Quantity
                                                    </th>
                                                    <th className="text-center" width="10%">
                                                        Total
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    props.values.products.length > 0 ? props.values.products?.map((item, index) => {
                                                        return (
                                                            <tr>

                                                                <td className="text-center">{item.product.name}</td>
                                                                <td className="text-center">{props.values.global_currency + item.product_price}</td>
                                                                <td className="text-center">{item.quantity}</td>
                                                                <td className="text-center">{props.values.global_currency + item.quantity * item.product_price}</td>
                                                            </tr>
                                                        )
                                                    }) : ''
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                    <table style={{ "width": "100%", "text-align": "right" }}>
                                        {props.values.order_type == "Delivery" ? <tr>
                                            <td ><b>DELIVERY CHARGE:</b></td>
                                            <td style={{ "width": "100px" }}>{props.values.global_currency + parseFloat(props.values.delivery_charge).toFixed(2)}</td>
                                        </tr> : ""}
                                        <tr>
                                            <td><b>SUB TOTAL:</b></td>
                                            <td style={{ "width": "100px" }}>{props.values.global_currency + parseFloat(props.values?.item_total).toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <td ><b>TAX TOTAL:</b></td>
                                            <td style={{ "width": "100px" }}>{props.values.global_currency + parseFloat(props.values.tax_total).toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <td ><b>OFFER AMOUNT:</b></td>
                                            <td style={{ "width": "100px" }}>{props.values.global_currency + parseFloat(props?.values?.offer_amount).toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <td ><b>GRAND TOTAL:</b></td>
                                            <td style={{ "width": "100px" }}>{props.values.global_currency + parseFloat(props?.values?.order_total).toFixed(2)}</td>
                                        </tr>



                                    </table>
                                    <hr></hr>
                                    {
                                        props.values.order_type == "Delivery" ? <div><h5>Driver Information</h5>
                                            <hr></hr>
                                            <table style={{ "width": "50%", "text-align": "left" }}>

                                                <tr>
                                                    <td><b>Name of the Driver:</b></td>
                                                    <td>{props.values.driverInfo?.first_name}</td>
                                                </tr>


                                            </table> </div> : ''
                                    }
                                </div>
                                <div className="invoice-footer">

                                    <p className="text-center">

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </Modal.Body>
        </div>
    );
}
export default TransactionInvoice;