import PaginationComponent from "../staff";

function Staff() {
  return (
    <div className="App">
      <div>
        <PaginationComponent />
      </div>
    </div>
  );
}

export default Staff;