import Header from "./header"
import Sidebar from "./menu"
import * as URL from '../../src/services/ServiceConstant';
import apiRequest from "../services/ServiceRequest";
import { getUser } from '../utils/common';
import { validateName, validateEmail, validateMobileNo } from '../helpers/validation';
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import placeholder from '../assets/img/user_placeholder.png';
import * as EmailTemplates from '../utils/emailtemplates.js';
const Profile = (props) => {
    toast.configure()
    let history = useHistory();
    var profiles_image = []
    var method = ''
    const [email, setemail] = useState("");
    const [first_name, setfirst_name] = useState("");
    const [last_name, setlast_name] = useState("");
    const [phone_number, setphone_number] = useState("");
    const [id, setId] = useState("");
    const [profile_image, setprofile_image] = useState("");
    const [view_first_name_error, first_name_error] = useState("");
    const [view_Last_name_error, Last_name_error] = useState("");
    const [view_Email_error, Email_error] = useState(false);
    const [view_Mobile_error, Mobile_error] = useState(false);
    const [viewFirstNameError, setFirstNameError] = useState(false);
    const [viewLastNameError, setLastNameError] = useState(false);
    const [viewEmailError, setEmailError] = useState(false);
    const [viewMobileError, setMobileError] = useState(false);
    const [view_profileimage_error, profileimage_error] = useState(false);
    const [viewProfileImageError, setProfileImageError] = useState(false);
    useEffect(async () => {
        var user = getUser();
        let endpoint;
        if (localStorage.getItem("role") == "businessowner") {
            endpoint = `${URL.API_URL}/BusinessOwners/${user}`

        }
        else {
            endpoint = `https://delapi.jumpgrowth.com/api/LocationManagers/${user}`
        }
        var get_staff = await apiRequest([], endpoint, 'get')
        if (get_staff == null || get_staff == undefined || get_staff.data.length <= 0) {
            history.push('/')
        }
        else {
            setprofile_image(get_staff.data.profile_image)
            setfirst_name(get_staff.data.first_name)
            setlast_name(get_staff.data.last_name)
            setemail(get_staff.data.email)
            setphone_number(get_staff.data.phone_number ? get_staff.data.phone_number : get_staff.data.mobile)
            setId(get_staff.data.id)
        }
    }, []);
    const notify = (message) => {
        toast.success(message)
    }
    const errornotify = (message) => {
        toast.error(message)
    }

    const digits_only = string => [...string].every(c => '0123456789'.includes(c));

    const onSubmit = async () => {
        let isnum = digits_only(phone_number);
        if (isnum == false) {
            alert("Phone Number should be Numeric!!");
            return false;
        }
        if (phone_number.length != 10) {
            alert("Phone Number should be exactly 10 numeric digits!!");
            return false;
        }
        if (first_name.length > 30 || last_name.length > 30) {
            alert("Firstname or Lastname exceeds excessive characters!!");
            return false;
        }
        if (first_name == '' || last_name == '' || email == '' || phone_number == '') {
            setfirst_name(first_name)
            const _ValidateFirstName = validateName(first_name, 'first name');
            if (_ValidateFirstName['status']) {
                first_name_error('')
                setFirstNameError(true)
            }
            else {
                first_name_error(_ValidateFirstName['error'])
                setFirstNameError(false)
            }
            setlast_name(last_name)
            const _ValidateLastName = validateName(last_name, 'last name');
            if (_ValidateLastName['status']) {
                Last_name_error('')
                setLastNameError(true)
            }
            else {
                Last_name_error(_ValidateLastName['error'])
                setLastNameError(false)
            }
            setphone_number(phone_number)
            const _ValidateMobileNo = validateMobileNo(phone_number, 'mobile number');
            if (_ValidateMobileNo['status']) {
                Mobile_error('')
                setMobileError(true)
            }
            else {
                Mobile_error(_ValidateMobileNo['error'])
                setMobileError(false)
            }
            setemail(email)
            const _ValidateEmail = validateEmail(email, 'email');
            if (_ValidateEmail['status']) {
                Email_error('')
                setEmailError(true)
            }
            else {
                Email_error(_ValidateEmail['error'])
                setEmailError(false)
            }
        }
        else {
            let formData = new FormData();
            var data = {
                "first_name": first_name,
                "last_name": last_name,
                "email": email,
                "profile_image": "",
                "phone_number": phone_number,
            }
            try {
                if (profile_image.profile_image != null && profile_image.profile_image != undefined) {
                    formData.append('profile_image', profile_image.profile_image);
                    let image_response = await apiRequest(formData, URL.UPLOAD_IMAGE, 'post')
                    if (image_response.error) {
                        errornotify("'Failed', response.error.message");
                        return;
                    }
                    data.profile_image = URL.image_url + image_response.data.profile_image
                }
                else {
                    data.profile_image = profile_image
                }
                method = 'patch'
                data.id = id
                let endpoint;
                let emails = await apiRequest([], URL.BASE_URL + '/emails', 'get');
                const index = emails.data.emails.indexOf(email);
                if (index > -1) {
                    emails.data.emails.splice(index, 1);
                }
                if (emails.data.emails.includes(email) == true) {
                    errornotify('Email already exists!!');
                    return false;
                }
                if (localStorage.getItem("role") == "businessowner") {
                    endpoint = `https://delapi.jumpgrowth.com/api/BusinessOwners`
                }
                else {
                    endpoint = `https://delapi.jumpgrowth.com/api/LocationManagers`
                }
                let staff_response = await apiRequest(data, endpoint, method)
                if (staff_response.error) {
                    errornotify("'Failed', response.error.message");
                } else {
                    notify("Updated successfully");
                    var user = getUser();
                    let endpoint;
                    let notification = false;
                    if (localStorage.getItem("role") == "businessowner") {
                        endpoint = `https://delapi.jumpgrowth.com/api/BusinessOwners/${user}`
                        notification = false;
                    }
                    else {
                        endpoint = `https://delapi.jumpgrowth.com/api/LocationManagers/${user}`
                        notification = false;
                    }
                    var get_staff = await apiRequest([], endpoint, 'get')
                    setprofile_image(get_staff.data.profile_image)
                    setfirst_name(get_staff.data.first_name)
                    setlast_name(get_staff.data.last_name)
                    setemail(get_staff.data.email)
                    setphone_number(get_staff.data.phone_number)
                    setId(get_staff.data.id)
                    if (notification == true) {
                        await apiRequest({
                            title: "Location manager has updated his profile",
                            message: "Location manager has updated his profile",
                            role: "businessowner",
                            type: "web",
                            status: "open",
                            locationmanagerId: get_staff.data.id,
                            datetime: new Date().toISOString()
                        }, URL.BASE_URL + '/Notifications', 'post');
                        await EmailTemplates.profileUpdated_LocationManager({
                            location_manager_name: first_name,
                            location_manager_phone: phone_number,
                            location_manager_email: email
                        });
                    }
                }
            }
            catch (err) {
                errornotify('Failed');
            }
        }
    };
    const _onChangeImage = async (images) => {
        if (images.type != "image/jpeg" && images.type != "image/jpg" && images.type != "image/png") {
            profileimage_error("Please upload the following file types: jpeg, and png.");
            return false;
        }
        var _size = images.size;
        if (_size > 1000000) {
            profileimage_error("The size limit for images is 1MB. Reduce the file size and try again.");
            return false;
        }
        setProfileImageError(true)
        let formData = new FormData();
        formData.append('profile_image', images);
        let image_response = await apiRequest(formData, URL.UPLOAD_IMAGE, 'post')
        if (image_response.error) {
            errornotify("'Failed', response.error.message");
            return;
        }
        setprofile_image(URL.image_url + image_response.data.profile_image)
    }
    const _onChangeText = (type, text) => {
        if (type === 'first_name') {
            if (text !== '') {
                setfirst_name(text)
                const _ValidateFirstName = validateName(text, 'first name');
                if (_ValidateFirstName['status']) {
                    first_name_error('')
                    setFirstNameError(true)
                }
                else {
                    first_name_error(_ValidateFirstName['error'])
                    setFirstNameError(false)
                }
            }
            else if (text === '' || text === undefined || text === null) {
                setfirst_name(text)
                first_name_error('Please enter  first name')
                setFirstNameError(false)
            }
        }
        if (type === 'last_name') {
            if (text !== '') {
                setlast_name(text)
                const _ValidateLastName = validateName(text, 'last name');
                if (_ValidateLastName['status']) {
                    Last_name_error('')
                    setLastNameError(true)
                }
                else {
                    Last_name_error(_ValidateLastName['error'])
                    setLastNameError(false)
                }
            }
            else
                Last_name_error('Please enter last name.')
            setLastNameError(false)
            setlast_name(text)
        }
        if (type === 'phone_number') {
            if (text !== '') {
                setphone_number(text)
                const _ValidateMobileNo = validateMobileNo(text, 'phone number');
                if (_ValidateMobileNo['status']) {
                    Mobile_error('')
                    setMobileError(true)
                }
                else {
                    Mobile_error(_ValidateMobileNo['error'])
                    setMobileError(false)
                }
            }
            else
                Mobile_error('Please enter mobile number.')
            setMobileError(false)
            setphone_number(text)
        }
        if (type === 'email') {
            if (text !== '') {
                setemail(text)
                const _ValidateEmail = validateEmail(text, 'email');
                if (_ValidateEmail['status']) {
                    Email_error('')
                    setEmailError(true)
                }
                else {
                    Email_error(_ValidateEmail['error'])
                    setEmailError(false)
                }
            }
            else
                Email_error('Please enter valid email.')
            setEmailError(false)
            setemail(text)
        }
    }
    const handleCallback = async (childData) => {

    }
    return (
        <div>
            <html lang="en">
                <div class="sticky">
                    <Header parentCallback={handleCallback} />
                </div>
                <body>
                    <div class="middle_block">
                        <Sidebar />
                        <div class="main_content">
                            <div class="container-fluid">
                                <div class="my_profile">

                                    <div class="sec_title">
                                        <h2 class="head_sty1">My Profile</h2>
                                    </div>
                                    <section class="staff_profile_sec">
                                        <div class="row">
                                            <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                                                <div class="staff_profile_pic_box mng-profile">
                                                    <div class="staff_profile_img">
                                                        {profile_image ? <img src={profile_image} alt="" /> : ""}
                                                        {
                                                            (profile_image == '') ?
                                                                <img src={placeholder} alt="" /> : <img src={profile_image} alt="" />}
                                                    </div>
                                                    <label class="custom-file-upload profilefileupload">
                                                        <input type="file" value={profiles_image} onChange={e => _onChangeImage(e.target.files[0])} />
                                                        Upload Image
                                                    </label>
                                                    {
                                                        (viewProfileImageError) ?
                                                            <small></small> : <><small style={{ color: 'red' }}>{view_profileimage_error}</small></>
                                                    }

                                                </div></div>


                                            <div class="col-lg-8 col-md-8 col-sm-8 col-12">
                                                <div class="staff_profile_form">
                                                    <div class="row">
                                                        <div class="col-md-6 col-sm-12">
                                                            <div class="form-group emailprofile">
                                                                <label>First Name</label>
                                                                <input type="text" class="form-control" value={first_name} onChange={e => _onChangeText('first_name', e.target.value)} />
                                                                {
                                                                    viewFirstNameError ? null : <><small style={{ color: 'red' }}>{view_first_name_error}</small><br /></>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 col-sm-12">
                                                            <div class="form-group emailprofile">
                                                                <label>Last Name</label>
                                                                <input type="text" class="form-control" value={last_name} onChange={e => _onChangeText('last_name', e.target.value)} />
                                                                {

                                                                    viewLastNameError ? null : <><small style={{ color: 'red' }}>{view_Last_name_error}</small><br /></>}<br />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 col-sm-12">
                                                            <div class="form-group emailprofile">
                                                                <label>Email Address</label>
                                                                <input type="text" class="form-control" value={email} onChange={e => _onChangeText('email', e.target.value)} />
                                                                {
                                                                    (viewEmailError) ?
                                                                        null : <><small style={{ color: 'red' }}>{view_Email_error}</small><br /></>}<br />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 col-sm-12">
                                                            <div class="form-group emailprofile">
                                                                <label>Contact Number</label>
                                                                <input type="text" class="form-control" value={phone_number} onChange={e => _onChangeText('phone_number', e.target.value)} />
                                                                {
                                                                    viewMobileError ? null : <><small style={{ color: 'red' }}>{view_Mobile_error}</small><br /></>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12" >
                                                            <div style={{ "margin-top": "7px" }} class="form-group mb-0 staff_profile_btn_group">
                                                                <button type="submit" class="btn_sty_1" onClick={() => onSubmit()}>Submit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div></div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </body>
            </html>
        </div>
    )
}
export default Profile;