import { useHistory } from 'react-router-dom';
import * as URL from '../../src/services/ServiceConstant';
import { dynamicStylesheet, getUser, setUserSession } from '../utils/common';
import { useState, useEffect } from 'react';
import { Link, BrowserRouter as Router } from 'react-router-dom'
import apiRequest from "../services/ServiceRequest";
import { validateEmail, validatePassword } from "../helpers/validation";
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Login = (props) => {
    const [logo, setLogo] = useState('N/A');
    const [emailAdd, setEmailAdd] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState({
        emailId: '',
        password: '',
        emailIdStatus: false,
        passwordStatus: false,
    });
    let history = useHistory();
    useEffect(async() => {
        let dynamicCSS = await dynamicStylesheet();
        var style = document.createElement('style');
        style.innerHTML = dynamicCSS.css;
        document.getElementsByTagName('head')[0].appendChild(style);
        var projectSettings = await apiRequest([],URL.BASE_URL+'/projectSettings/','get');
        if(projectSettings.data.extracted[0].logo!="N/A"){
            setLogo(projectSettings.data.extracted[0].logo);
        }
    },[]);
    const _onChangeText = (type, text) => {
        if (type === 'EA') {
            if (text !== '') {
                setEmailAdd(text.toLowerCase());
                const _ValidateEmail = validateEmail(text);
                if (_ValidateEmail['status'])
                    setError({ ...error, emailId: '', emailIdStatus: true });
                else
                    setError({
                        ...error,
                        emailId: _ValidateEmail['error'],
                        emailIdStatus: false,
                    });
            }
            else {
                setError({
                    ...error,
                    emailId: 'Please enter valid email.',
                    emailIdStatus: false,
                })
                setEmailAdd(text);
            }
        }
        else if (type === 'PWD') {
            if (text !== '') {
                setPassword(text);
                const _ValidatePassword = validatePassword(text);
                if (_ValidatePassword['status']) {
                    setError({ ...error, password: '', passwordStatus: true });
                }
                else {
                    setError({
                        ...error,
                        password: _ValidatePassword['error'],
                        passwordStatus: false,
                    });
                }
            }
            else {
                setError({
                    ...error,
                    password: 'Please enter valid password.',
                    passwordStatus: false,
                })
                setPassword(text);
            }
        };
    }
    const _validationSignIn = () => {
        error['emailIdStatus']
            ? error['passwordStatus']
                ? _signin()
                : setError({
                    ...error,
                    password: 'Please enter password.',
                    passwordStatus: false,
                })
            : setError({
                ...error,
                emailId: 'Please enter your email address.',
                emailIdStatus: false,
            });
    };
    const redirect = () => {
        history.push('forgotpassword')
    }
    const errornotify = (message) => {
        toast.error(message)
    }
    const _signin = async () => {
        const data = {
            email: emailAdd.toLowerCase(),
            password: password,
            id_deleted : false
        };
        let role = await apiRequest({ email: emailAdd.toLowerCase() }, URL.BASE_URL+'/get-role-by-email','post');
        let ROLEOFUSER = role.data.role;
        let response;
        let id_deleted_url = '';
        if(ROLEOFUSER=="businessowner")
        {
            response = await apiRequest(data, URL.LOGIN, 'post')
        }
        if(ROLEOFUSER=="locationmanager")
        {
            response = await apiRequest(data, URL.BASE_URL + '/LocationManagers/login', 'post');
        }
        if (response === null || response === undefined || role.data.role=="invalid") {
            errornotify("Invalid login");
            history.push('/')
        }
        else {
            localStorage.setItem("role",ROLEOFUSER);
            setUserSession(response.data.id, response.data.userId);
            localStorage.setItem("currentUser", response.data.userId);
            const user = getUser();
            if(ROLEOFUSER=="locationmanager")
            {
                var locs = await apiRequest([],URL.BASE_URL+'/LocationManagers/'+user,'get');
                localStorage.setItem("locations", locs.data.locations_assigned);
                id_deleted_url = await apiRequest([], URL.BASE_URL + '/LocationManagers/' + user, 'get');
                var locationmanagersfilter = await apiRequest([], URL.BASE_URL + `/LocationManagers?filter={"where":{"email":"${emailAdd}"}}`, 'get');
                var locationmanagerFinal = locationmanagersfilter.data;
                var filter = locationmanagerFinal.filter(function (item) {
                    return item.id_deleted == false && item.block_status=="unblocked";
                })
                if (filter.length > 0) {
                    setUserSession(response?.data?.id, filter[0]?.id);
                    localStorage.setItem('username', locationmanagerFinal?.[0]?.first_name);
                } else {
                    errornotify("Invalid login!!");
                    history.push('/')
                    return false;
                }
            }
            window.location.href="/home"
        };
    }
    const EmptyValue = (type) => {
        if(type=="email"){
            setEmailAdd("");
        }else{
            setPassword("");
        }
    };
    return (
        <div>
            <html lang="en">
                <head>
                    <meta charset="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                    <link rel="shortcut icon" type="image/png" href="img/logo.png" />
                    <link rel="preconnect" href="https://fonts.googleapis.com" />
                    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
                    <link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap" rel="stylesheet" />
                    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous" />
                    <link rel="stylesheet" href="css/icofont.css" />
                    <link rel="stylesheet" href="css/jquery.mCustomScrollbar.css" />
                    <link rel="stylesheet" href="css/custom.css" />
                    <title>Food Delivery</title>
                </head>
                <body>
                <ToastContainer/>
                    <div class="account_form">
                        <div class="row">
                        
                            <div class="col-md-12 text-center">
                                {
                                logo=="N/A" ? 
                                '' :
                                <img style={{"width" : "500px", "height" : "100px"}} src={logo}></img>
                                }
                                <h2 class="account_page_title">Sign In</h2>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Email Address</label>
                                    <input placeholder="Enter Email.." type="text"
                                        // onClick={() => EmptyValue('email')} 
                                        class="form-control" value={emailAdd} onChange={e => _onChangeText('EA', e.target.value)} />
                                    <small style={{ color: 'red' }}> {!error.emailIdStatus ? error['emailId'] : ''}</small>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Password</label>
                                    <input placeholder="Enter Password.." type="password"
                                        // onClick={() => EmptyValue('password')}
                                        class="form-control" value={password} onChange={e => _onChangeText('PWD', e.target.value)} />
                                    <small style={{ color: 'red' }}>{!error.passwordStatus ? error['password'] : ''}</small>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <button type="submit" class="btn_sty_1" onClick={() => _validationSignIn()} >LOGIN</button>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group text-center">
                                    <Router>
                                        <Link class="account_bottom_link" to="/forgotpassword" onClick={redirect}>Forgot Password?</Link>
                                    </Router>
                                </div>
                            </div>
                        </div>
                    </div>
                    <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                    <script src="js/jquery.mCustomScrollbar.concat.min.js"></script>
                </body>
            </html>
        </div>
    );
}
export default Login;