import Moment from "react-moment";
function BannersList(props) {
    var valueProps = props.values;
    return (
        <div>
            {
                valueProps.data.length > 0
                    ? valueProps.data?.map((item, index) => {
                        return (
                            <div class="m_banners_box">
                            <div class="m_banners_top m_banner_top">
                                <div class="img_box"><img src={item?.image} alt="" /></div>
                                <div class="m_banners_info">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <h2 class="r_name">{item?.name}</h2>
                                            </div>
                                            {
                                                (item?.expiry >= new Date().toISOString()) ? 
                                                    <div class="col-md-6 r_align_right view_id_btn_wrap">
                                                        <label class="switch">
                                                            <input type="checkbox" disabled={false}
                                                                checked={valueProps.get_data[index].enable} value={item.enable} onChange={e => valueProps.handleCheckbox(item, index)} />
                                                            <span class="slider round"></span> </label>
                                                    </div>
                                                    : 
                                                    <div class="col-md-6 r_align_right view_id_btn_wrap">
                                                        <p style={{ "color": "red" }}>Expired</p>
                                                    </div>
                                            }
                                        
                                        <div class="col-md-12">
                                            <p>{item?.description}</p>
                                        </div>
                                    </div>
                                    <div class="m_banners_bottom">
                                        <h4><span>Schedule:</span> <Moment format={valueProps.global_date ? valueProps.global_date : "DD/MM/YYYY"}>
                                            {valueProps.getStartDate(item?.start_date)}
                                        </Moment>  - <Moment format={valueProps.global_date ? valueProps.global_date : "DD/MM/YYYY"}>
                                                {item?.expiry}
                                            </Moment></h4>
                                        <div class="btn_group"> <a href="#" class="grey_btn" data-toggle="modal" data-target="#edit_bannersModal" onClick={e => valueProps.onEdit(item)}>Edit</a>
                                            <button class="grey_btn" onClick={e => valueProps.onDelete(item?.id)}>Remove</button>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                </div>
                        )
                    })
                    : <div align="center" style={{ 'font-size': '16' }} >{valueProps.view_show_msg}</div>
            }
        </div>
    );
}
export default BannersList;