import axios from 'axios';
import { getToken } from '../utils/common';
import { Link, BrowserRouter as Router } from 'react-router-dom'
const apiRequest = async (variables, URL, apiMethod) => {
  const token = getToken();
  if (token !== null && token !== undefined && token !== "") {
    var header = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      AUTHORIZATION: token
    };
  }
  else {
    <Link to='/'></Link>
  }
  try {
    var response;
    if (apiMethod == 'post') {
      response = await axios.post(`${URL}`, variables, { headers: header });
    }
    else if (apiMethod == 'put') {
      response = await axios.put(`${URL}`, variables, { headers: header });
    }
    else if (apiMethod == 'patch') {
      response = await axios.patch(`${URL}`, variables, { headers: header });
    }
    else if (apiMethod == 'delete') {
      response = await axios.delete(`${URL}`, { headers: header });
    }
    else if (apiMethod == 'get') {
      response = await axios.get(`${URL}`, { headers: header });
    }
    if (response.status >= 200 && response.status < 500) {
      try {
        return response;
      }
      catch (error) {
        return { status: response.status, message: '' }
      }
    }
    else if (response.status >= 500 && response.status < 600) {
      return { status: false, message: 'Internal Server Error' }
    }
    else return { status: false, message: 'Network request failed' }
  } catch (error) {
  }
};
export default apiRequest;
